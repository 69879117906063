import { ExcelJsonParametersRequest } from './../../models/general/excelJsonParametersRequest';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatCheckbox, MatTable, MatDialog } from '@angular/material';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { datePeriodConverter } from 'src/app/functions/date-period';
import { excelLiquidacionesRequest } from 'src/app/models/general/excelLiquidacionesRequest';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { DynamicTable } from 'src/app/models/rrhh/dynamic-table.model';
import { FileService } from 'src/app/services/file.service';
import { ReporteService } from 'src/app/services/reporte.service';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalActualizacionJornadaComponent } from '../modals/modal-actualizacion-jornada/modal-actualizacion-jornada.component';
import { ModalJornadaProgramadaDetComponent } from '../modals/modal-jornada-programada-det/modal-jornada-programada-det.component';

@Component({
  selector: 'app-novedades-timeline',
  templateUrl: './novedades-timeline.component.html',
  styleUrls: ['./novedades-timeline.component.scss']
})
export class NovedadesTimelineComponent implements OnInit {
  dataSource = new MatTableDataSource<any>([]);
  columnsDef: string[];
  groupColDef: any;
  groupColHeaders: string[];
  dynamicData: DynamicTable;
  selected = -1;
  row: any;
  paramEntity = new ParamEntity<any>();
  @ViewChild('detallePaginator', { static: true }) paginator: MatPaginator;
  stickyColumns = [
    'SECCION',
    'ID_LEGAJO',
    'APELLIDO',
    'NOMBRE'
    // ,'accion'
  ];
  stickyAgrupadoresHeaders = [
    'DATOS-PERSONALES'
  ];
  contentLoaded = false;
  rowsSelected: { rows?: any[], checkbox?: MatCheckbox[] } = { rows: [], checkbox: [] };
  rangeDates: { start, end };

  matTable: MatTable<any>;
  @ViewChild('matTable', { static: false }) set table(matTable: MatTable<any>) {
    if (matTable) {
      this.matTable = matTable;
      this.ngZone.onMicrotaskEmpty
        .pipe(take(3))
        .subscribe(() => matTable.updateStickyColumnStyles())
    }
  }

  @ViewChild('chkBox', { static: false }) chkBox;
  inputFilterText: string;

  periodoDesdeSeleccionado: any;
  periodoHastaSeleccionado: any;
  agrupadoresList: any;
  pConceptoSelected = 0;
  novedadFormControl = new FormControl();
  checkSoloNovedades: boolean =false;

  constructor(
    private novedadesService: NovedadesService,
    private userValuesService: UserValuesService,
    private _snackBar: SnackBarService,
    private reporteService: ReporteService,
    private senderService: SenderService,
    private elementRef: ElementRef,
    private dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef,
    private ngZone: NgZone,
    private datePipe: DatePipe,
    private fileService: FileService
  ) { }

  ngOnInit() {
    this.getIncidenciasAgrupadores();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2500);
  }

  private getTableData() {
    const paramEntity = { ...this.paramEntity };
    paramEntity.Json = JSON.stringify({
      "pIdEmpresa": 1,
      "pIdUsuario": this.paramEntity.IdUsuario,
      "pPeriodo": null,
      "pFechaDesde": this.datePipe.transform(this.paramEntity.PeriodoDesde, 'yyyyMMdd'),
      "pFechaHasta": this.datePipe.transform(this.paramEntity.PeriodoHasta, 'yyyyMMdd'),
      "pIdSeccion": this.paramEntity.IdSeccion,
      "pIdLegajo": -1,
      "pConcepto": this.pConceptoSelected,
      "pVerSoloNovedades": this.checkSoloNovedades?1:0

    })

    this.novedadesService.getNovedadesTimeline(paramEntity).subscribe((result: any) => {
      this.clearData();
      this.dynamicData = JSON.parse(result[0].vJsonResult);
      this.dataSource.data = this.dynamicData.data;
      this.createTableStructure();
      this.changeDetectorRefs.detectChanges();

    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
    this.hideSpinner();
  }

  private getIncidenciasAgrupadores() {
    const paramEntity = { ...this.paramEntity };
    paramEntity.Json = null;

    this.novedadesService.getIncidenciasAgrupadores(paramEntity).subscribe((result: any) => {
      this.agrupadoresList = result;

      const agrupadorPlaceholder = this.agrupadoresList.find(x => x.ID_AGRUPADOR === 0);
      if (agrupadorPlaceholder) {
        this.novedadFormControl.setValue(agrupadorPlaceholder);
      }
    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });

  }

  getNovedadSelected(e) {
    this.pConceptoSelected = e.option.value.ID_AGRUPADOR;
  }

  displayFn(item): string {
    return item && item.N_AGRUPADOR ? item.N_AGRUPADOR : '';
  }

  private createTableStructure() {
    this.columnsDef = this.dynamicData.estructure.reduce((a, b) => a.concat(b.columns), []);
    // this.columnsDef.unshift('accion');
    this.groupColDef = this.dynamicData.estructure.reduce((a, b) => a.concat(b), []);
    this.groupColHeaders = this.dynamicData.estructure.reduce<string[]>((a, b) => a.concat(b.colDef), []);
    // this.groupColHeaders.unshift('accion');
  }

  private clearData() {
    this.columnsDef = [];
    this.groupColHeaders = [];
    this.dynamicData = null as DynamicTable;
    this.dataSource = new MatTableDataSource<any>();
    this.groupColDef = null;
  }

  getRowClassCondition(row) {
    if (row.FECHA_BAJA) {
      return 'bg-row-date';
    }

    if (this.rowsSelected.rows.indexOf(row) != -1) {
      return 'selected-row';
    }

  }

  filter(event: Filters, fromMultipleFilters = true, clearTable = false) {
    this.paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    this.paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    if (fromMultipleFilters) {
      this.paramEntity.Periodo = '';
      this.paramEntity.PeriodoDesde = event.periodo.desde;
      this.paramEntity.PeriodoHasta = event.periodo.hasta;
      this.paramEntity.IdSeccion = event.idSector;
      this.paramEntity.IdLegajo = event.legajo;
      this.periodoDesdeSeleccionado = event.periodo.desde;
      this.periodoHastaSeleccionado = event.periodo.hasta;

    } else {
      this.paramEntity.SpName = event.reporte.SpName;
    }

    this.paramEntity.ClearTable = clearTable;

    // this.paramEntity = { ...this.paramEntity };
    // this.senderService.enviarObjetoFiltros(this.paramEntity);
    this.rowsSelected = { rows: [], checkbox: [] };
    this.getTableData();

  }

  printFile(soloDescarga: boolean, url?, fileName?) {
    // DESCOMENTAR CUANDO HAYA QUE IMPLEMENTAR
    const paramEntity = { ...this.paramEntity };
    paramEntity.Json = JSON.stringify({
      "pIdEmpresa": 1,
      "pIdUsuario": this.paramEntity.IdUsuario,
      "pPeriodo": null,
      "pFechaDesde": this.datePipe.transform(this.paramEntity.PeriodoDesde, 'yyyyMMdd'),
      "pFechaHasta": this.datePipe.transform(this.paramEntity.PeriodoHasta, 'yyyyMMdd'),
      "pIdSeccion": this.paramEntity.IdSeccion,
      "pIdLegajo": -1,
      "pConcepto": this.pConceptoSelected,
      "pVerSoloNovedades": this.checkSoloNovedades?1:0

    })


    let params = new ExcelJsonParametersRequest;
    params.pJsonParameters = paramEntity.Json;
    params.fileName = "Novedades (timeline).xlsx";

    //this.loadingFile = true; // inhabilito todos los controles del html
    // SI ES TRUE, USO TIMEOUT PARA QUE NO SE PISE CON EL MENSAJE ANTERIOR
    setTimeout(() => {
      this._snackBar.openSnackBar('snack-warning', 'Generando archivo. Por favor espere.');
    }, 1000);

    this.fileService.downloadXlsxExcel_NovedadesTimeline(params).subscribe((resultDownload) => {
      if (resultDownload) {
        if (!soloDescarga) {
          // SI SE DESCARGA CORRECTAMENTE LIMPIO TODO Y ACTUALIZO LOS DATOS
          //console.log("Descarga Ok");
        }
        // USO EL TIMEOUT DE NUEVO PARA ELIMINAR EL MENSAJE ANTERIOR
        setTimeout(() => {
          this._snackBar.dismissSnackbar();
        }, 3000);

      } else {
        this._snackBar.openSnackBar('snack-danger', 'Hubo un error al intentar descargar el archivo', 3000);
      }
      //this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    }, (errorDescarga) => {
      this._snackBar.openSnackBar('snack-danger', errorDescarga, 5000);
      //this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    });
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isSticky(col, element) {
    if (element) {
      // APLICO ESTILO PARA COL FECHA_INGRESO_CALC
      if (col === 'FECHA_INGRESO_CALC' && element.FECHA_INGRESO_CALC) {
        try {
          const splitDate = element.FECHA_INGRESO_CALC.split('/');
          const newDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
          const periodDate = datePeriodConverter(newDate, true);
          if (periodDate === this.paramEntity.Periodo) {
            return 'bg-celda';
          }
        } catch (error) { }
      }

      // NO APLICA ESTILO PARA CELDA FECHA_BAJA
      if (element.FECHA_BAJA) {
        return false;
      }

      // SE APLICA CLASE A LAS COLUMNAS FIJAS
      if (this.stickyColumns.includes(col)) {
        return 'bg-fixed-col';
      } else {
        if (col === 'IMPARES' && Number(element.IMPARES) > 0) { // ESTILO PARA LA COL IMPARES CON CONDICION
          return 'bg-celda';
        }
        if (col === 'SIN_INCIDENCIA' && Number(element.SIN_INCIDENCIA) > 0) { // ESTILO PARA LA COL SIN_INCIDENCIA CON CONDICION
          return 'bg-celda';
        }

        if(element[col] && element[col].Value==1){
          if(element[col].jerarquia<=10){
            return 'dStyleWithValueAviso';
          }
          if(element[col].jerarquia>10){
            return 'dStyleWithValueJustificacion';
          }
        }

        if(element[col] && element[col].esFeriado==1){
            return 'dStyleFeriado';
        }

        if(element[col] && (element[col].dia=="SÁBADO" || element[col].dia=="DOMINGO")){
          return 'dStyleSabadoDomingo';
      }

        return element[col] && element[col].style ? element[col].style : '';
      }
    }
    return this.stickyColumns.includes(col);
  }

  getCellData(value, col) {
    const dateArrValues = [
      'FECHA_INGRESO_CALC',
      'FECHA_BAJA'
    ]
    if (dateArrValues.includes(col)) {
      return this.datePipe.transform(value, 'dd/MM/yyyy');
    }

    let valor = value;
    if (col != 'SECCION' && col != 'ID_LEGAJO' && col != 'APELLIDO' && col != 'NOMBRE') {
      valor = value != undefined ? value.Value : null;
    }
    return valor;
  }

  getCellTooltip(value, col) {
    let valor = value;
    if (col != 'SECCION' && col != 'ID_LEGAJO' && col != 'APELLIDO' && col != 'NOMBRE') {
      if (value && value.style) {
        switch (value.style) {
          case "dStyleLegajoInactivo":
            valor = "Legajo Inactivo";
            break;
          case "dStyleSinGestionar":
            valor = "Sin Gestionar";
            break;
          default:
            if (value.Value == 1) {
              valor = value.nIncidencia;
            } else {
              valor = null;
            }
            break;
        }
      }

    } else {
      valor = null;
    }

    return valor;
  }

  hideSpinner() {
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
  }

  checkboxSelect(e: MatCheckbox, element) {
    if (this.rowsSelected.checkbox.indexOf(e) === -1) {
      this.rowsSelected.checkbox.push(e);
      // return;
    }

    if (this.rowsSelected.rows.indexOf(element) === -1) {
      this.rowsSelected.rows.push(element);
      return;
    }

    const index = this.rowsSelected.rows.indexOf(element);
    if (index !== -1) {
      this.rowsSelected.rows.splice(index, 1);
    }

    const chkIndex = this.rowsSelected.checkbox.indexOf(e);
    if (chkIndex !== -1) {
      this.rowsSelected.checkbox.splice(chkIndex, 1);
    }
  }

  openModalData(row) {
    const dialogRef = this.dialog.open(ModalJornadaProgramadaDetComponent, {
      width: '800px',
      height: '400px',
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: {
        titulo: 'Detalle Jornadas Programadas',
        obj: row,
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result.actualizarTabla) {
        this.actualizarTabla(row.element, true);
      }
    });
  }

  get(col, element) {
    let nameCol: string = col.substr(0, 10);
    if (nameCol != 'SECCION' && nameCol != 'ID_LEGAJO' && nameCol != 'APELLIDO' && nameCol != 'NOMBRE') {
      const fecha = moment(col.substr(0, 10), "DD/MM/YYYY").toDate();
      const row = {
        fecha,
        element
      };
      // this.openModalData(row);
    }
  }

  openModal() {
    let dialogRef = this.dialog.open(ModalActualizacionJornadaComponent, {
      width: '800px',
      height: 'auto',
      minHeight: '500px',
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: {
        titulo: 'Asignar Jornada',
        obj: this.rowsSelected.rows,
        rangeDates: this.rangeDates
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result instanceof Array) {
        this.rowsSelected.rows = [];
        this.inputFilterText = '';
        this.updateTableRows(result);
      } else if (result) {
        this.rowsSelected.rows = [];
        this.inputFilterText = '';
        // this.getTableData();
        this.paramEntity.ClearTable = true;
        this.senderService.enviarObjetoFiltros(this.paramEntity);
      }

    });
    dialogRef.afterClosed().subscribe(x => { dialogRef = null })
  }

  getRangeValues(rangeValues) {
    this.rangeDates = rangeValues;
  }

  deseleccionarTodo() {
    // this.rowsSelected.rows = [];
    // this.selected = -1;
    this.rowsSelected.checkbox.forEach((checkbox: MatCheckbox) => {
      checkbox.checked = false;
    });
    this.rowsSelected.rows = [];
    this.selected = -1;
    // const checkboxes = document.querySelectorAll('.mat-checkbox');
    // checkboxes.forEach((checkbox) => {
    //   checkbox.classList.remove('mat-checkbox-checked');
    // });
  }

  actualizarTabla(elementRow, refreshTable) {
    const paramEntity = { ...this.paramEntity };
    paramEntity.IdLegajo = elementRow.ID_LEGAJO;
    paramEntity.PeriodoDesde = this.periodoDesdeSeleccionado;
    paramEntity.PeriodoHasta = this.periodoHastaSeleccionado;

    this.novedadesService.getJornadasProgramadasUpdate(paramEntity).subscribe((result: any) => {
      if (result[0]) {
        //this.clearData();
        var nuevaData = JSON.parse(result[0].vJsonResult)

        var nuevaLista = this.mergeArrays(this.dynamicData.data, nuevaData.data);
        this.dynamicData.data = nuevaLista;
        this.dataSource.data = nuevaLista;


        if (refreshTable) {
          this.createTableSrtucture();
        }

      }
    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
    this.hideSpinner();
  }

  mergeArrays(arr, obj) {
    return (arr && arr.map(t => t.ID_LEGAJO === obj[0].ID_LEGAJO ? obj[0] : t)).slice();
  }

  private createTableSrtucture() {
    this.createTableStructure();
    this.changeDetectorRefs.detectChanges();
    this.matTable.renderRows();
  }

  private updateTableRows(result) {
    result.forEach((element, index, array: []) => {
      const request = {
        ID_LEGAJO: element.id_legajo
      };
      const refreshTable = true; //index === (array.length - 1); // verifico si es el ultimo elemento para poder refrescar la tabla
      this.actualizarTabla(request, refreshTable);

      if (refreshTable) {
        this.paramEntity.ClearTable = true;
        this.senderService.enviarObjetoFiltros(this.paramEntity);
      }
    });
  }

}
