import { ResponseHelper } from './../../models/sistema/responseHelper';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { datePeriodConverter } from 'src/app/functions/date-period';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Reporte } from 'src/app/models/rrhh/reporte.model';
import { ReporteService } from 'src/app/services/reporte.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MatTableDataSource, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { DatePipe } from '@angular/common';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-reporting-individual',
  templateUrl: './reporting-individual.component.html',
  styleUrls: ['./reporting-individual.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
})
export class ReportingIndividualComponent implements OnInit, OnDestroy {
  paramEntity = new ParamEntity<any>();
  filters = new Filters();
  reporteSelected: Reporte;
  reporteResult: any;
  @Input() dataSource = new MatTableDataSource<any>([]);
  columns: string[];

  constructor(
    private userValuesService: UserValuesService,
    private senderService: SenderService,
    private reporteService: ReporteService,
    private _snackBar: SnackBarService,
    private novedadesService: NovedadesService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.paramEntity.ClearTable = true;
    this.senderService.enviarObjetoFiltros(this.paramEntity);
  }

  printExcel(e: any) {
    setTimeout(() => {
      this._snackBar.openSnackBar('snack-warning', 'Generando archivo. Por favor espere.');
    }, 1000);

    this.paramEntity.Json = JSON.stringify({
      pIdEmpresa: this.userValuesService.getUsuarioValues.IdEmpresa,
      pSpName: this.reporteSelected.SpName,
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario,
      pFechaDesde: e.periodo ? this.datePipe.transform(e.periodo.desde, 'dd/MM/yyyy') : '',
      pFechaHasta: e.periodo ? this.datePipe.transform(e.periodo.hasta, 'dd/MM/yyyy') : '',
      pIdLegajo: e.empleadoSelected.IdLegajo,
      pSeleccionaPeriodo: e.isPeriodoSeleccionado ? 1 : 0,
      pPeriodoSelect: e.periodoSelect
    });

    this.reporteService.getExcelFileByJsonService(this.paramEntity).subscribe((res) => {
      if (res) {
        setTimeout(() => {
          this._snackBar.dismissSnackbar();
        }, 3000);
      }
    });
  }

  getReporteSelected(e: Filters) {
    this.reporteSelected = e.reporte;
  }

  search(e: Filters) {

    if (!this.reporteSelected || !this.reporteSelected.SpName) {
      this._snackBar.openSnackBar('snack-danger', 'Seleccione un reporte');
      setTimeout(() => {
        this._snackBar.dismissSnackbar();
      }, 3000);
      return;
    }


    if (e.legajo==0) {
      this._snackBar.openSnackBar('snack-danger', 'Seleccione algún colaborador');
      setTimeout(() => {
        this._snackBar.dismissSnackbar();
      }, 3000);
      return;
    }


    if (e.isPeriodoSeleccionado && e.periodoSelect==undefined) {
      this._snackBar.openSnackBar('snack-danger', 'Seleccione algún período');
      setTimeout(() => {
        this._snackBar.dismissSnackbar();
      }, 3000);
      return;
    }

    this.paramEntity.Json = JSON.stringify({
      pIdEmpresa: this.userValuesService.getUsuarioValues.IdEmpresa,
      pSpName: this.reporteSelected.SpName,
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario,
      pFechaDesde: e.periodo ? this.datePipe.transform(e.periodo.desde, 'dd/MM/yyyy') : '',
      pFechaHasta: e.periodo ? this.datePipe.transform(e.periodo.hasta, 'dd/MM/yyyy') : '',
      pIdLegajo: e.empleadoSelected.IdLegajo,
      pSeleccionaPeriodo: e.isPeriodoSeleccionado ? 1 : 0,
      pPeriodoSelect: e.periodoSelect
    });

    this.reporteService.getReportingIndividualEndpoint(this.paramEntity).subscribe((res: ResponseHelper) => {
      if (res.Ok) {
        this.reporteResult = res.DataResponse;
        this.dataSource.data = res.DataResponse;
        if (res.DataResponse[0] != null) {
          this.columns = Object.keys(res.DataResponse[0]);
        } else {
          this.columns = null;
        }

      }
    });
  }
}
