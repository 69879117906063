import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NavEntity } from 'src/app/models/general/nav-entity.interface';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { getNavMenuList } from 'src/app/models/const/nav-const';
import { UserHubService } from 'src/app/services/utils/user-hub.service';

@Component({
  selector: 'app-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.css']
})
export class MenuNavComponent implements OnInit {

  @Output() HomeMenu_LoginAccion_prm = new EventEmitter();
  @Output() HomeMenu_ComponentShow_prm = new EventEmitter();
  idRol: number;
  navMenuList: Array<NavEntity> = [];
  userLabel: any;

  @Input() public set HomeMenu_SetOpcionesXRol_void(IdRol: number) {
    this.Set_HomeMenuXRol(IdRol);
  }

  isExpanded = false;

  constructor(
    private userValuesService: UserValuesService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private _snackBar: SnackBarService,
    private _userHubService: UserHubService) {
    // BACKUP MENU 
    // this.navMenuList = getNavMenuList(this.userValuesService.getUsuarioValues.IdRol, this.userValuesService.getUsuarioValues.IdUsuario);
    this.navMenuList = this.userValuesService.getUsuarioValues.SistMenu ?
      JSON.parse(this.userValuesService.getUsuarioValues.SistMenu) : null;
    if (!this.navMenuList) {
      this.cerrarSesion();
    }
  }

  ngOnInit() {
    this.Set_HomeMenuXRol(this.userValuesService.getUsuarioValues.IdRol);
    this.userLabel = this.userValuesService.getUsuarioValues.NomUsuario;
  }

  send_LoginAccion(idAccion: number) {
    this.HomeMenu_LoginAccion_prm.emit(idAccion);
  }


  Set_HomeMenuXRol(IdRol: number) {
    // if (IdRol === 0) {
    //   document.getElementById("btn_homemenu_sniper").style.display = "none";
    //   document.getElementById("btn_homemenu_nomina").style.display = "none";
    //   document.getElementById("btn_homemenu_novedades").style.display = "none";
    //   document.getElementById("btn_homemenu_reportes").style.display = "none";
    // }
    // if (IdRol == 1) {
    //   document.getElementById("btn_homemenu_sniper").style.display = "block";
    //   document.getElementById("btn_homemenu_nomina").style.display = "block";
    //   document.getElementById("btn_homemenu_novedades").style.display = "block";
    //   document.getElementById("btn_homemenu_reportes").style.display = "block";
    // }

    // if (IdRol == 2) {
    //   document.getElementById("btn_homemenu_sniper").style.display = "none";
    //   document.getElementById("btn_homemenu_nomina").style.display = "block";
    //   document.getElementById("btn_homemenu_novedades").style.display = "block";
    //   document.getElementById("btn_homemenu_reportes").style.display = "none";
    // }
  }

  HomeMenu_ComponentShow_void(component: string) {
    this.HomeMenu_ComponentShow_prm.emit(component);
  }

  cerrarSesion() {
    // Detener la conexión SignalR al cerrar sesión
    this._userHubService.closeConnection(this.userValuesService.getUsuarioValues.userHubId).then(() => {
      // Realizar acciones adicionales después de detener la conexión
      console.log('Conexión SignalR detenida');
    }).catch((err: any) => console.error('Error al detener la conexión SignalR', err));
  
    // Cerrar sesión en el servicio de valores de usuario
    this.userValuesService.setLogout();
  }

  filterMenuItems(menuItems) {
    return menuItems.filter(x => !x.hidden);
  }
}
