import { SourcePlantillasJornadas } from './../../../models/sources/sourcePlantillasJornadas';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material';


@Component({
  selector: 'app-config-plantillas-jornadas-list-plantillas',
  templateUrl: './config-plantillas-jornadas-list-plantillas.component.html',
  styleUrls: ['./config-plantillas-jornadas-list-plantillas.component.scss']
})
export class ConfigPlantillasJornadasListPlantillasComponent implements OnInit {

  @Input() dataSourceList: SourcePlantillasJornadas[];
  control = new FormControl();


  @ViewChild('matSelection', { static: true }) matSelection: MatSelectionList;
  elementsSelected: { value; selected }[];
  @Output() elementSelectedEmit = new EventEmitter();
  @Output() editElementEmit = new EventEmitter();

  constructor(
  ) { }

  ngOnInit() {
    // this.dataShareService.selectedElement$.subscribe((selected: any[]) => {
    //   if (selected) {
    //     this.elementsSelected = selected;
    //   }
    // });
     this.matSelection.selectedOptions = new SelectionModel<MatListOption>(false);
  }

  getSelected(e: MatSelectionListChange) {
    const element = e.option.selected ? e.option.value : null;
    this.elementSelectedEmit.emit(element);
  }

  setCheckValues(idPlantilla: number){
     this.matSelection.options.forEach(x => {
       if (x.value.IdPlantillaJornada==idPlantilla) {
         x._setSelected(true);
       }else{
         x._setSelected(false);
       }
     })
   }

  clearForms() {
    this.matSelection.deselectAll();
    this.elementSelectedEmit.emit([]);
  }
}
