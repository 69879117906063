import { SourceUsuariosXplantillaNomina } from './../../../models/sources/sourceUsuariosXplantillaNomina';
import { SourcePlantillasNomina } from './../../../models/sources/sourcePlantillasNomina';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material';
import { SourcePlantillasNominaXempleados } from 'src/app/models/sources/sourcePlantillasNominaXempleados';

@Component({
  selector: 'app-list-plantillas-nomina',
  templateUrl: './list-plantillas-nomina.component.html',
  styleUrls: ['./list-plantillas-nomina.component.scss']
})
export class ListPlantillasNominaComponent implements OnInit {
  @Input() plantillasNominaList: SourcePlantillasNomina[];
  @ViewChild('matSelection', { static: false }) matSelection: MatSelectionList;
  @Output() optionsPlantillaNominaSelectedEmit = new EventEmitter();
  listPlantillaChecked: number[] = [];
  @Input() plantillaxUsuarioList: SourceUsuariosXplantillaNomina[];


  constructor() { }

  ngOnInit() {
  }

  getSelected(e) {
     if (e.option.selected) {
      this.listPlantillaChecked.push(e.option.value.IdPlantillaNomina);
      this.optionsPlantillaNominaSelectedEmit.emit(this.listPlantillaChecked);
    }else{
      const index = this.listPlantillaChecked.findIndex(x => x === e.option.value.IdPlantillaNomina);
      this.listPlantillaChecked.splice(index, 1);
      this.optionsPlantillaNominaSelectedEmit.emit(this.listPlantillaChecked);
    }

  }



  setCheckValues(idUsuario: number) {
    this.listPlantillaChecked = [];
    var listPlantillaXusuario: number[] = this.plantillaxUsuarioList.filter(x => x.IdUsuario === idUsuario).map(x => x.IdPlantillaNomina);

     this.matSelection.options.forEach(x => {
       if (listPlantillaXusuario.indexOf(x.value.IdPlantillaNomina)>-1) {
         x._setSelected(true);
         this.listPlantillaChecked.push(x.value.IdPlantillaNomina);
       }else{
         x._setSelected(false);
       }
     });
     //console.log(this.listPlantillaChecked);
   }


  clearForms() {
    this.matSelection.deselectAll();
    this.listPlantillaChecked=[];
    this.optionsPlantillaNominaSelectedEmit.emit(this.listPlantillaChecked);
  }

}
