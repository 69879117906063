import { SnackBarService } from './../../services/utils/snackBar.service';
import { ResponseHelper } from './../../models/sistema/responseHelper';
import { NovedadesService } from './../../services/rrhh/novedades/novedades.service';
import { NovedadesEndPoint } from './../../services/rrhh/novedades/novedades-endpoint';
import { ParamEntity } from './../../models/general/param.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GestionDocumental } from 'src/app/models/rrhh/gestion-documental';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { Idle } from 'idlejs/dist';

export enum State {
  MOSTRAR_CODIGO = 1,
  MOSTRAR_FORM = 2,
  MOSTRAR_MENSAJE = 3
}

@Component({
  selector: 'app-gestion-documental',
  templateUrl: './gestion-documental.component.html',
  styleUrls: ['./gestion-documental.component.scss']
})
export class GestionDocumentalComponent implements OnInit {
  form: FormGroup;
  gestionDocumental: GestionDocumental = {} as GestionDocumental;
  showCodeRequest = true;
  codeEmited: any;
  routeCode: any;
  observacionsInput: any;
  accionSelected;
  state: State = State.MOSTRAR_CODIGO;
  stateEnum = State;

  idle;

  constructor(
    private userValuesService: UserValuesService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private novedadesService: NovedadesService,
    private _snackBar: SnackBarService
  ) {
    this.userValuesService.setLoggedInBehavior(false);
    this.routeCode = atob(this.route.snapshot.params['id']);

    if (localStorage.getItem('pass')) {
      this.codeEmited = atob(localStorage.getItem('pass'));
      this.getCodeEmitted(this.codeEmited);
    }
  }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      observaciones: [],
      idSelect: []
    });
  }

  getCodeEmitted(e) {
    this.codeEmited = e;

    if (this.codeEmited) {
      this.setInactivity();

      const params = new ParamEntity<any>();
      params.IdDocumento = this.routeCode;
      params.Password = this.codeEmited;

      this.novedadesService.getGestionDoc(params).subscribe((result: ResponseHelper) => {
        if (result.Ok) {
          this.showCodeRequest = false;
          this.gestionDocumental = result.DataResponse;
          this.gestionDocumental.ListAcciones = JSON.parse(result.DataResponse.ListAcciones);
          this.state = this.stateEnum.MOSTRAR_FORM;
        } else {
          this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
        }
      })
    }
  }

  getAccionSelected(e) {
    this.accionSelected = e;
  }

  getObservacion(e) {
    this.observacionsInput = e;
  }

  guardar() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      "password": this.codeEmited.toString(),
      "idDocumento": this.routeCode,
      "idAccion": this.accionSelected,
      "observaciones": this.observacionsInput
    });

    this.novedadesService.guardarGestionDocumental(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', result.Mensaje, 3000);
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }
      this.state = this.stateEnum.MOSTRAR_MENSAJE;
    }, (error) => { this._snackBar.openSnackBar('snack-error', 'Hubo un error. Intente nuevamente', 3000); });
  }

  private setInactivity() {
    localStorage.setItem('pass', btoa(this.codeEmited));
    this.idle = new Idle()
      .whenNotInteractive()
      .within(2)
      .do(() => {
        localStorage.removeItem('pass');
        this.state = this.stateEnum.MOSTRAR_CODIGO;
      }).start();
  }

}
