import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TipoJornadaEnum, TipoTurnoEnum } from 'src/app/enums/configuracion.enum';
import { ParamEntity } from 'src/app/models/general/param.model';
import { ConfiguracionJornada, DatosCalculadosTurno, Plantilla, Turno, UsuariosPlantilla } from 'src/app/models/rrhh/configuracion-jornada';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ListJornadasComponent } from '../configuracion-jornadas/list-jornadas/list-jornadas.component';
import { ListPlantillasComponent } from '../configuracion-jornadas/list-plantillas/list-plantillas.component';
import { ListUsuariosPlantillaComponent } from '../configuracion-jornadas/list-usuarios-plantilla/list-usuarios-plantilla.component';
import { FormConfigAComponent } from './form-config-a/form-config-a.component';
import { FormConfigBComponent } from './form-config-b/form-config-b.component';
import { FormConfigCComponent } from './form-config-c/form-config-c.component';
import { Usuario } from '../../models/general/usuario.model';
import { SnackBarService } from '../../services/utils/snackBar.service';
import { ModalConfirmacionComponent } from '../modals/modal-confirmacion/modal-confirmacion.component';
import { ModalAbmJornadasPlantillasComponent } from './modal-abm-jornadas-plantillas/modal-abm-jornadas-plantillas.component';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-configuracion-jornadas',
  templateUrl: './configuracion-jornadas.component.html',
  styleUrls: ['./configuracion-jornadas.component.scss']
})
export class ConfiguracionJornadasComponent implements OnInit {
  usuario: Usuario;
  result: ConfiguracionJornada[] = [];
  resultOriginal: ConfiguracionJornada[] = [];
  jornadaSeleccionada: ConfiguracionJornada = {} as ConfiguracionJornada;
  tipoJornadaSelected: TipoJornadaEnum;
  tipoJornadaEnum = TipoJornadaEnum;
  tipoTurnoSelected: TipoTurnoEnum;
  tipoTurnoEnum = TipoTurnoEnum;
  dataUsuariosPlantilla: UsuariosPlantilla[] = [];
  dataUsuariosPlantillaFiltered: UsuariosPlantilla[] = [];
  plantillaSeleccionada: Plantilla;
  clearForms = false;
  plantillasListResult: Plantilla[];
  configuracionToSave: ConfiguracionJornada = {} as ConfiguracionJornada;
  turnoUnoToSave: Turno;
  turnoDosToSave: Turno;
  datosCalculadosTurno1: DatosCalculadosTurno = {} as DatosCalculadosTurno;
  datosCalculadosTurno2: DatosCalculadosTurno = {} as DatosCalculadosTurno;
  jornadaSelected: ConfiguracionJornada;
  inputFiltroLegajo = new FormControl();

  @ViewChild(ListJornadasComponent, { static: false }) listJornadasComponent: ListJornadasComponent;
  @ViewChild(ListPlantillasComponent, { static: false }) listPlantillasComponent: ListPlantillasComponent;
  @ViewChild(ListUsuariosPlantillaComponent, { static: false }) listUsuariosPlantillaComponent: ListUsuariosPlantillaComponent;
  @ViewChild(FormConfigAComponent, { static: false }) formConfigAComponent: FormConfigAComponent;
  @ViewChild(FormConfigBComponent, { static: false }) formConfigBComponent: FormConfigBComponent;
  @ViewChild(FormConfigCComponent, { static: false }) formConfigCComponent: FormConfigCComponent;

  constructor(
    private configuracionService: ConfiguracionService,
    private userValuesService: UserValuesService,
    private novedadesService: NovedadesService,
    private _snackBar: SnackBarService,
    public dialog: MatDialog
  ) {
    this.usuario = this.userValuesService.getUsuarioValues;
  }

  ngOnInit() {
    this.loadJornadas();
  }

  private loadJornadas(configuracionSelected?) {
    this.getAbmJornadas();
    this.getAbmJornadasUsuariosPlantilla();
    this.jornadaSelected = configuracionSelected;
  }

  private getAbmJornadas() {
    const paramEntity = new ParamEntity();
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    setTimeout(() => {
      this.configuracionService.getAbmJornadasGetService(paramEntity).subscribe((result: ConfiguracionJornada[]) => {
        this.result = result;
        this.resultOriginal = result;
      });
    }, 1000);
  }

  private getAbmJornadasUsuariosPlantilla() {
    this.configuracionService.getAbmJornadasUsuariosPlantillaGetService().subscribe((result: UsuariosPlantilla[]) => {
      if (result && result.length) {
        this.dataUsuariosPlantilla = result;
      }
    });
  }


  getJornadaSeleccionada(jornada) {
    this.jornadaSeleccionada = {} as ConfiguracionJornada;
    this.jornadaSeleccionada = jornada;
    this.plantillasListResult = Object.assign([], jornada.Plantillas);
    this.formConfigAComponent.datosCalculadosTurno1 = {} as DatosCalculadosTurno;
    this.formConfigAComponent.datosCalculadosTurno2 = {} as DatosCalculadosTurno;
    this.listUsuariosPlantillaComponent.limpiarList();
  }

  getPlantillaSelected(e) {
    if (e) {
      this.plantillaSeleccionada = e;
      this.filterUsuariosPlantilla();
    } else {
      this.dataUsuariosPlantillaFiltered = [];
    }
  }

  private filterUsuariosPlantilla() {
    this.dataUsuariosPlantillaFiltered = this.dataUsuariosPlantilla.filter(x => x.IdPlantilla == this.plantillaSeleccionada.IdPlantilla);
  }

  setTipoJornada(e) {
    this.tipoJornadaSelected = e;
  }

  setTipoTurno(e) {
    this.tipoTurnoSelected = e;
  }

  setClearForms(e) {
    this.clearForms = e;


    setTimeout(() => {
      this.clearForms = false;
    }, 500);

    this.formConfigAComponent.newJornada = true;
  }




  nuevaJornada() {
    this.listJornadasComponent.clearForms();
    this.formConfigAComponent.newJornada = true;
  }

  EliminarPlantilla() {
    this.listPlantillasComponent.anular();
  }


  getDatosCalculadosTurno1(element: DatosCalculadosTurno) {
    this.formConfigAComponent.datosCalculadosTurno1 = element;
    this.formConfigAComponent.actualizarHTeoricas();
    this.validarTurnos();
  }

  getDatosCalculadosTurno2(element: DatosCalculadosTurno) {
    this.formConfigAComponent.datosCalculadosTurno2 = element;
    this.formConfigAComponent.actualizarHTeoricas();
    this.validarTurnos();
  }

  validarTurnos() {
    //Turno 1: ingreso no puede ser posterior al egreso
    if (this.formConfigAComponent.datosCalculadosTurno1.horaIngreso > this.formConfigAComponent.datosCalculadosTurno1.horaEgreso) {
      if (this.formConfigBComponent.form.controls.horaEgreso.value != undefined && this.formConfigBComponent.form.controls.horaIngreso.value != undefined) {
        let hIngreso: string = this.formConfigBComponent.form.controls.horaIngreso.value;
        let hEgreso: string = this.formConfigBComponent.form.controls.horaEgreso.value;
        if (hIngreso.length == 5 && hEgreso.length == 5) {
          this.formConfigBComponent.form.controls.horaEgreso.setValue(hIngreso);
        }
      }
    }

    //Turno 2: ingreso no puede ser posterior al egreso
    if (this.formConfigAComponent.datosCalculadosTurno2.horaIngreso > this.formConfigAComponent.datosCalculadosTurno2.horaEgreso) {
      if (this.formConfigCComponent.form.controls.horaEgreso.value != undefined && this.formConfigCComponent.form.controls.horaIngreso.value != undefined) {
        let hIngreso: string = this.formConfigCComponent.form.controls.horaIngreso.value;
        let hEgreso: string = this.formConfigCComponent.form.controls.horaEgreso.value;
        if (hIngreso.length == 5 && hEgreso.length == 5) {
          this.formConfigCComponent.form.controls.horaEgreso.setValue(hIngreso);
        }
      }
    }


    this.formConfigBComponent.turnosSuperpuestos = false;
    this.formConfigCComponent.turnosSuperpuestos = false;

    if (this.formConfigAComponent.tipoJornadaSelect == this.formConfigAComponent.tipoJornadaEnum.ESPECIFICA
      && this.formConfigAComponent.tipoTurnoSelect == this.formConfigAComponent.tipoJTurnoEnum.DOBLE) {

      let T1hIngreso: string = this.formConfigBComponent.form.controls.horaIngreso.value == null ? '' : this.formConfigBComponent.form.controls.horaIngreso.value;
      let T1hEgreso: string = this.formConfigBComponent.form.controls.horaEgreso.value == null ? '' : this.formConfigBComponent.form.controls.horaEgreso.value;

      let T2hIngreso: string = this.formConfigCComponent.form.controls.horaIngreso.value == null ? '' : this.formConfigCComponent.form.controls.horaIngreso.value;
      let T2hEgreso: string = this.formConfigCComponent.form.controls.horaEgreso.value == null ? '' : this.formConfigCComponent.form.controls.horaEgreso.value;


      let T1MargenIngreso: string = this.formConfigBComponent.form.controls.margenIngreso.value == null ? '' : this.formConfigBComponent.form.controls.margenIngreso.value;
      let T1MargenEgreso: string = this.formConfigBComponent.form.controls.margenEgreso.value == null ? '' : this.formConfigBComponent.form.controls.margenEgreso.value;

      let T2MargenIngreso: string = this.formConfigCComponent.form.controls.margenIngreso.value == null ? '' : this.formConfigCComponent.form.controls.margenIngreso.value;
      let T2MargenEgreso: string = this.formConfigCComponent.form.controls.margenEgreso.value == null ? '' : this.formConfigCComponent.form.controls.margenEgreso.value;



      if (T1hIngreso.length == 5 && T1hEgreso.length == 5
        && T2hIngreso.length == 5 && T2hEgreso.length == 5
        && T1MargenIngreso.length == 5 && T1MargenEgreso.length == 5
        && T2MargenIngreso.length == 5 && T2MargenEgreso.length == 5
      ) {
        if (this.formConfigAComponent.datosCalculadosTurno1.horaEgresoConMargen >= this.formConfigAComponent.datosCalculadosTurno2.horaIngresoConMargen) {
          this.formConfigBComponent.turnosSuperpuestos = true;
          this.formConfigCComponent.turnosSuperpuestos = true;
        } else {
          this.formConfigBComponent.turnosSuperpuestos = false;
          this.formConfigCComponent.turnosSuperpuestos = false;
        }
      }
    }
  }

  submitForm(element: ConfiguracionJornada) {

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '500px',
      height: '120px',
      autoFocus: false,
      data: {
        titulo: '¿Desea gardar los cambios realizados?'
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {


        this.configuracionToSave = Object.assign({}, element);
        this.configuracionToSave.TurnoUno = this.turnoUnoToSave;
        this.configuracionToSave.TurnoDos = this.turnoDosToSave;
        this.configuracionToSave.Plantillas = this.plantillasListResult;

        if (Object.keys(this.jornadaSeleccionada).length === 0) {
          this.configuracionToSave.IdJornada = 0;
        } else {
          this.configuracionToSave.IdJornada = this.jornadaSeleccionada.IdJornada;
        }


        this.configuracionToSave.TurnoUno.Hteoricas = this.formConfigBComponent.HTeoricas;
        this.configuracionToSave.TurnoDos.Hteoricas = this.formConfigCComponent.HTeoricas;


        this.configuracionToSave.IdEstado = (this.configuracionToSave.IdEstado == null) ? 1 : this.configuracionToSave.IdEstado;
        this.configuracionToSave.IdIncidencia = (this.configuracionToSave.IdIncidencia == null) ? 0 : this.configuracionToSave.IdIncidencia;
        this.configuracionToSave.EsFranco = (this.configuracionToSave.EsFranco == null) ? false : this.configuracionToSave.EsFranco;
        this.configuracionToSave.RefrirgerioMinutos = (this.configuracionToSave.RefrirgerioMinutos == null) ? 0 : this.configuracionToSave.RefrirgerioMinutos;
        this.configuracionToSave.Hteoricas = (this.configuracionToSave.Hteoricas == null) ? 0 : this.configuracionToSave.Hteoricas;


        this.configuracionToSave.TurnoUno.Hinicio = (this.configuracionToSave.TurnoUno.Hinicio == null || this.configuracionToSave.TurnoUno.Hinicio == '') ? '00:00' : this.configuracionToSave.TurnoUno.Hinicio;
        this.configuracionToSave.TurnoUno.Hegreso = (this.configuracionToSave.TurnoUno.Hegreso == null || this.configuracionToSave.TurnoUno.Hegreso == '') ? '00:00' : this.configuracionToSave.TurnoUno.Hegreso;
        this.configuracionToSave.TurnoUno.MargenInicio = (this.configuracionToSave.TurnoUno.MargenInicio == null || this.configuracionToSave.TurnoUno.MargenInicio == '') ? '00:00' : this.configuracionToSave.TurnoUno.MargenInicio;
        this.configuracionToSave.TurnoUno.MargenEgreso = (this.configuracionToSave.TurnoUno.MargenEgreso == null || this.configuracionToSave.TurnoUno.MargenEgreso == '') ? '00:00' : this.configuracionToSave.TurnoUno.MargenEgreso;
        this.configuracionToSave.TurnoUno.TolIngreso = (this.configuracionToSave.TurnoUno.TolIngreso == null || this.configuracionToSave.TurnoUno.TolIngreso == '') ? '00:00' : this.configuracionToSave.TurnoUno.TolIngreso;
        this.configuracionToSave.TurnoUno.TolEgreso = (this.configuracionToSave.TurnoUno.TolEgreso == null || this.configuracionToSave.TurnoUno.TolEgreso == '') ? '00:00' : this.configuracionToSave.TurnoUno.TolEgreso;

        this.configuracionToSave.TurnoDos.Hinicio = (this.configuracionToSave.TurnoDos.Hinicio == null || this.configuracionToSave.TurnoDos.Hinicio == '') ? '00:00' : this.configuracionToSave.TurnoDos.Hinicio;
        this.configuracionToSave.TurnoDos.Hegreso = (this.configuracionToSave.TurnoDos.Hegreso == null || this.configuracionToSave.TurnoDos.Hegreso == '') ? '00:00' : this.configuracionToSave.TurnoDos.Hegreso;
        this.configuracionToSave.TurnoDos.MargenInicio = (this.configuracionToSave.TurnoDos.MargenInicio == null || this.configuracionToSave.TurnoDos.MargenInicio == '') ? '00:00' : this.configuracionToSave.TurnoDos.MargenInicio;
        this.configuracionToSave.TurnoDos.MargenEgreso = (this.configuracionToSave.TurnoDos.MargenEgreso == null || this.configuracionToSave.TurnoDos.MargenEgreso == '') ? '00:00' : this.configuracionToSave.TurnoDos.MargenEgreso;
        this.configuracionToSave.TurnoDos.TolIngreso = (this.configuracionToSave.TurnoDos.TolIngreso == null || this.configuracionToSave.TurnoDos.TolIngreso == '') ? '00:00' : this.configuracionToSave.TurnoDos.TolIngreso;
        this.configuracionToSave.TurnoDos.TolEgreso = (this.configuracionToSave.TurnoDos.TolEgreso == null || this.configuracionToSave.TurnoDos.TolEgreso == '') ? '00:00' : this.configuracionToSave.TurnoDos.TolEgreso;
        let validacionResult = this.validarDatos(this.configuracionToSave);
        if (validacionResult) {
          this.guardar(this.configuracionToSave);
          this.loadJornadas(Object.assign({}, this.configuracionToSave));
          this.configuracionToSave = {} as ConfiguracionJornada;
        };

      }
    });

  }

  getFormTurnoUno(e) {
    this.turnoUnoToSave = {
      SignoIngreso: e.diaIngreso,
      Hinicio: e.horaIngreso,
      MargenInicio: e.margenIngreso,
      TolIngreso: e.toleranciaIngreso,
      SignoEgreso: e.diaEgreso,
      Hegreso: e.horaEgreso,
      MargenEgreso: e.margenEgreso,
      TolEgreso: e.toleranciaEgreso

    };
  }

  getFormTurnoDos(e) {
    this.turnoDosToSave = {
      SignoIngreso: e.diaIngreso,
      Hinicio: e.horaIngreso,
      MargenInicio: e.margenIngreso,
      TolIngreso: e.toleranciaIngreso,
      SignoEgreso: e.diaEgreso,
      Hegreso: e.horaEgreso,
      MargenEgreso: e.margenEgreso,
      TolEgreso: e.toleranciaEgreso
    };
  }


  private validarDatos(jornada: ConfiguracionJornada) {

    if (jornada.Njornada == null || jornada.Njornada.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar un nombre de Jornada.');
      return false;
    }


    if (jornada.TipoJornada == 1) {
      if (!jornada.EsFranco && (jornada.Hteoricas == 0 || jornada.Hteoricas == null)) {
        this.mostrarMensajeValidacion('Si la jornada no es de tipo FRANCO, las horas teóricas deben ser mayor a 0 (cero).');
        return false;
      }
    }

    if (jornada.Hteoricas >= 24) {
      this.mostrarMensajeValidacion('El total de "Horas Teóricas" de la jornada debe ser inferior a 24 hs.');
      return false;
    }

    if (jornada.TipoJornada == 2 && jornada.TipoTurno == 1) {
      let validacionTurnoResult: boolean;
      validacionTurnoResult = this.validarTurno(jornada.TurnoUno, 'Turno 1');
      if (!validacionTurnoResult) {
        return false;
      }
    }


    if (jornada.TipoJornada == 2 && jornada.TipoTurno == 2) {
      let validacionTurnoResult: boolean;
      validacionTurnoResult = this.validarTurno(jornada.TurnoUno, 'Turno 1');
      if (!validacionTurnoResult) {
        return false;
      }

      validacionTurnoResult = this.validarTurno(jornada.TurnoDos, 'Turno 2');
      if (!validacionTurnoResult) {
        return false;
      }
      if (this.formConfigAComponent.datosCalculadosTurno1.horaEgresoConMargen >= this.formConfigAComponent.datosCalculadosTurno2.horaIngresoConMargen) {
        this.mostrarMensajeValidacion('Los turnos no deben superponerse, verifique los rangos horarios (considerando los márgenes de cada turno).');
        return false;
      }
    }


    return true;
  }

  private validarTurno(turno: Turno, nombreTurno: string) {
    if (turno.Hinicio.length < 5 ||
      turno.MargenInicio.length < 5 ||
      turno.TolIngreso.length < 5 ||
      turno.Hegreso.length < 5 ||
      turno.MargenEgreso.length < 5 ||
      turno.TolEgreso.length < 5
    ) {
      this.mostrarMensajeValidacion('Verifique que los horarios ingresados en el ' + nombreTurno + ' posean el formato correcto (HH:MM).');
      return false;
    }

    if (turno.Hinicio == turno.Hegreso) {
      this.mostrarMensajeValidacion('Verifique que la "Hora de Ingreso" sea distinta a la "Hora de Egreso" (' + nombreTurno + ').');
      return false;
    }


    return true;
  }


  private mostrarMensajeValidacion(mensaje: string) {
    this._snackBar.openSnackBar('snack-danger', mensaje, 5000);
  }


  private guardar(jornada: ConfiguracionJornada) {
    const param = new ParamEntity();
    param.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    param.IdUsuario = this.usuario.IdUsuario;
    param.Json = JSON.stringify(jornada);

    this.novedadesService.guardarConfiguracionJornadaUpd(param).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', result.Mensaje, 3000);
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }
    }, (error) => { this._snackBar.openSnackBar('snack-error', 'Hubo un error. Intente nuevamente', 3000); });
  }



  //openModalPlantillas() {
  //  this.listPlantillasComponent.openModal();
  //}


  openModalPlantillas() {
    const dialogRef = this.dialog.open(ModalAbmJornadasPlantillasComponent, {
      width: '800px',
      height: '500px',
      autoFocus: false,
      data: {
        plantillas: this.plantillasListResult
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue) {
      this.result = this.resultOriginal.filter(x =>
        (x.Njornada).toLowerCase().includes(filterValue.toLowerCase())
        || x.IdJornada.toString().includes(filterValue.toString())
      )
    } else {
      this.result = this.resultOriginal;
    }

  }

}
