import { ExcelJsonParametersRequest } from './../models/general/excelJsonParametersRequest';
import { FileEntity } from './../models/general/file.entity';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, throwError, TimeoutError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { ResponseHelper } from "../models/sistema/responseHelper";
import { NovedadesEndPoint } from 'src/app/services/rrhh/novedades/novedades-endpoint';
import { ParamEntity } from "../models/general/param.model";
import { LoadingInterceptorService } from './utils/loader-interceptor.service';
import { docVacacionesModel } from '../models/rrhh/documentos/docVacaciones.model';
import { tcontroldetParams } from './../models/general/tcontrolDet.entity';
import { excelLiquidacionesRequest } from '../models/general/excelLiquidacionesRequest';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  /* Variables para llamar a API */
  private readonly _urlArchivos: string = '/api/file';

  constructor(private http: HttpClient, private loadingInterceptorService: LoadingInterceptorService) { }

  private handleError(error: HttpErrorResponse) {
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    return throwError(error);
  }

  protected getRequestHeaders() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + '',
      'Content-Type': 'application/json',
      'Accept': `application/json, text/plain, */*`,
      'App-Version': '1.0',
      timeout: `${100000}`
    });

    return headers;
  }

  downloadPdf(params?: FileEntity) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/descargaArchivo`;

    const paramsEndPoint = new HttpParams()
      .set('filtro', JSON.stringify(params.Url));

    this.http.get(url, { headers: this.getRequestHeaders(), params: paramsEndPoint, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.printFile(fileResponse, params);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }



  downloadDocPdf(params?: FileEntity) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/descargaArchivo`;

    const paramsEndPoint = new HttpParams()
      .set('filtro', JSON.stringify(params.Url));

    this.http.get(url, { headers: this.getRequestHeaders(), params: paramsEndPoint, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.printFile(fileResponse, params);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }




  downloadDocVacacionesPdf(paramsBody?: docVacacionesModel) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/descargaArchivo`;

    let httOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'arraybuffer'
    }

    this.http.post(url, JSON.stringify(paramsBody), httOptions).pipe(
      catchError(error => {
        return this.handleError(error);
      })).subscribe((fileResponse) => {
        if (fileResponse) {
          this.printDocVacaciones(fileResponse, paramsBody);
          sub.next(true);
        } else {
          sub.error(false);
        }
      });
    return sub;
  }


  downloadXlsx(params?: FileEntity) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/descargaArchivo`;

    const paramsEndPoint = new HttpParams()
      .set('filtro', JSON.stringify(params.Url));

    this.http.get(url, { headers: this.getRequestHeaders(), params: paramsEndPoint, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.printFileXlsx(fileResponse, params);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }

  downloadXlsxLiquidacionesConsolidadas(params?: excelLiquidacionesRequest) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/Excel_LiquidacionesConsolidadas`;

    const paramsEndPoint = new HttpParams()
      .set('idEmpresa', JSON.stringify(params.idEmpresa))
      .set('idUsuario', JSON.stringify(params.idUsuario))
      .set('periodo', JSON.stringify(params.periodo))
      .set('idSeccion', JSON.stringify(params.idSeccion));

    this.http.get(url, { headers: this.getRequestHeaders(), params: paramsEndPoint, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.print_Excel_Liquidaciones(fileResponse, params);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }

  downloadXlsxLiquidacionesDetalle(params?: excelLiquidacionesRequest) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/Excel_LiquidacionesDetalle`;

    const paramsEndPoint = new HttpParams()
      .set('idEmpresa', JSON.stringify(params.idEmpresa))
      .set('idUsuario', JSON.stringify(params.idUsuario))
      .set('IdLegajo', JSON.stringify(params.idLegajo))
      .set('periodo', JSON.stringify(params.periodo))
      .set('idSeccion', JSON.stringify(params.idSeccion));

    this.http.get(url, { headers: this.getRequestHeaders(), params: paramsEndPoint, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.print_Excel_Liquidaciones(fileResponse, params);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }




  downloadXlsxExcel_NovedadesTimeline(params?: ExcelJsonParametersRequest) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/Excel_NovedadesTimeline`;

    const paramsEndPoint = new HttpParams()
      .set('pJsonParameters', params.pJsonParameters);

    this.http.get(url, { headers: this.getRequestHeaders(), params: paramsEndPoint, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.print_Excel_NovedadesTimeline(fileResponse, params);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }

  private print_Excel_NovedadesTimeline(fileResponse, param: ExcelJsonParametersRequest) {
    var blob = new Blob([fileResponse], { type: 'application/vnd.ms-excel' })
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = param.fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }


  downloadXlsxExcel_StoredProcedure(params?: ExcelJsonParametersRequest) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/Excel_StoredProcedure`;

    const paramsEndPoint = new HttpParams()
      .set('pJsonParameters', params.pJsonParameters);

    this.http.get(url, { headers: this.getRequestHeaders(), params: paramsEndPoint, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.print_Excel_StoredProcedure(fileResponse, params);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }


  private print_Excel_StoredProcedure(fileResponse, param: ExcelJsonParametersRequest) {
    var blob = new Blob([fileResponse], { type: 'application/vnd.ms-excel' })
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = param.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }





  private printDocVacaciones(fileResponse, param: docVacacionesModel) {
    var blob = new Blob([fileResponse], { type: 'application/pdf' });
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = null;//param.idDocumento.toString();
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }


  Excel_TControlDet(params?: tcontroldetParams) {
    const sub = new Subject<boolean>();

    const url = `${this._urlArchivos}/Excel_TControlDet`;

    const paramsEndPoint = new HttpParams()
      .set('idEmpresa', params.idEmpresa.toString())
      .set('idTcontrol', params.idTcontrol.toString())
      .set('periodo', params.periodo.toString())
      .set('idUsuario', params.idUsuario.toString())
      .set('idSeccion', params.idSeccion.toString());

    this.http.get(url, { headers: this.getRequestHeaders(), params: paramsEndPoint, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.print_Excel_TControlDet(fileResponse, params);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }


  private printFile(fileResponse, param: FileEntity) {
    var blob = new Blob([fileResponse], { type: 'application/pdf' })
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = param.Filename;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }



  private printFileXlsx(fileResponse, param: FileEntity) {
    var blob = new Blob([fileResponse], { type: 'application/vnd.ms-excel' })
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = param.Filename + ".xlsx";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  private print_Excel_TControlDet(fileResponse, param: tcontroldetParams) {
    var blob = new Blob([fileResponse], { type: 'application/vnd.ms-excel' })
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = param.fileName + ".xlsx";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  getVacacionesPdfFile() {
    let path = 'assets/documents/docVacaciones.html';
    return this.http.get(path, { responseType: "text" });
  }

  private print_Excel_Liquidaciones(fileResponse, param: excelLiquidacionesRequest) {
    var blob = new Blob([fileResponse], { type: 'application/vnd.ms-excel' })
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    var year: string = param.periodo.substring(0, 4);
    var month: string = param.periodo.substring(4, 6);

    a.href = objectUrl;
    a.download = param.fileName + "(Periodo " + month + "-" + year + ").xlsx";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  guardarArchivo(request: ParamEntity<any>): Observable<any> {
    const sub = new Subject<boolean>();
    const url = `${this._urlArchivos}/guardarFotoNomina`;

    var result = this.http.post(url, JSON.stringify(request), { headers: this.getRequestHeaders(), responseType: 'arraybuffer' }).pipe(
      catchError(error => {
        return this.handleError(error);
      })).subscribe(fileResponse => {
        if (fileResponse) {
          sub.next(true);
        } else {
          sub.error(false);
        }
      });

    return sub;
  }

}
