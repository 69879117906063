import { CmbEntity } from './../../../models/general/cmbEntity.model';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { datePeriodConverter, dateStrSplitter } from 'src/app/functions/date-period';
import { getKeyValue } from 'src/app/functions/key-value';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Novedades } from 'src/app/models/rrhh/novedades/novedades.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { TableroService } from 'src/app/services/tablero.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalMarcacionComponent } from '../modal-marcacion/modal-marcacion.component';
import { ReturnStatement } from '@angular/compiler';

@Component({
  selector: 'app-modal-detalle-hs-extras',
  templateUrl: './modal-detalle-hs-extras.component.html',
  styleUrls: ['./modal-detalle-hs-extras.component.scss']
})
export class ModalDetalleHsExtrasComponent implements OnInit {
  dataObj: ParamEntity<any>;
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild('detallePaginator', { static: true }) paginator: MatPaginator;
  actualizaTabla = false;
  displayedColumns: string[];
  contentLoaded = false;
  stickyColumns = [
    'FECHA',
    'DIA',
    'N_JORNADA',
    'accion'
  ];
  motivosCmbList: CmbEntity[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ParamEntity<any>,
    public dialogRef: MatDialogRef<ModalDetalleHsExtrasComponent>,
    private novedadesService: NovedadesService,
    private userValuesService: UserValuesService,
    private _snackBar: SnackBarService,
    private tableroService: TableroService,
    public dialog: MatDialog
  ) {
    this.dataObj = data;
  }

  ngOnInit() {
    this.getTableData();
    this.getCmbMotivos();
  }

  cerrarModal() {
    this.dialogRef.close(this.actualizaTabla);
  }

  printFile() {

  }

  openModalData(row) {
    if (row && row.FECHA) { // SE TOMAN LOS CAMPOS LEGAJO Y FECHA TAL CUAL LLEGAN DEL SP
      const paramEntity = new ParamEntity();
      paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
      paramEntity.IdLegajo = this.dataObj.GenericEntity.ID_LEGAJO;
      paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
      paramEntity.FechaDate = dateStrSplitter(row.FECHA, true) as Date;

      this.tableroService.getNovedadDetalleService(paramEntity).subscribe((result: Novedades) => {
        if (result) {
          result.FechaDate = paramEntity.FechaDate; // adjunto la fecha tomada de la row
          this.showModal(result);
        } else {
          this._snackBar.openSnackBar('snack-danger', 'Intente nuevamente', 3000);
        }
      }, (error) => {
        this._snackBar.openSnackBar('snack-danger', 'Intente nuevamente', 3000);
      })

    }
  }

  private showModal(row) {
    const dialogRef = this.dialog.open(ModalMarcacionComponent, {
      width: '1000px',
      height: '500px',
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: {
        titulo: 'Novedades',
        obj: row,
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result.actualiza) {
        this.actualizaTabla = true;
        this.getTableData();
      }
    });
  }

  getTableData() {
    const paramEntity = new ParamEntity();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdSeccion = this.dataObj.IdSeccion;
    paramEntity.Periodo = this.dataObj.Periodo;
    paramEntity.IdLegajo = this.dataObj.GenericEntity.ID_LEGAJO;

    //console.log(paramEntity);
    this.novedadesService.getDetalleLiqHsExtras(paramEntity).subscribe((result: any[]) => {
      if (result.length) {
        const ignoredFields = [
          '^O_'
        ];
        this.displayedColumns = getKeyValue(result, ignoredFields)[0];
        this.displayedColumns.unshift('accion');
        this.dataSource.data = result;
        //console.log(result);
      }
    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
    this.hideSpinner();
  }

  showInput(e) {
    const ignore = ['_aut', '_sel'];
    return !ignore.includes(e.toLowerCase());
  }

  checkCol(e) {
    return e.toLowerCase().split('_').slice(-1);
  }

  formatColText(e) {
    if (e.toLowerCase().includes('_sel')) {
      return e.split('_')[0];
    }

    if (e.toLowerCase().includes('_input')) {
      return e.split('_')[0];
    }

    return e;
  }

  guardar() {
    let tableResult = this.dataSource.data.filter(x => x.HS_50_AUT>0 || x.HS_100_AUT>0);
    if (this.validarTabla(tableResult)) {
      const paramEntity = new ParamEntity();
      paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
      paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
      tableResult = this.dataSource.data.filter(x =>
        (x.HS_50_AUT !== null)
        ||
        (x.HS_100_AUT !== null)
      ).map(value => {
        return {
          ID_LEGAJO: this.dataObj.GenericEntity.ID_LEGAJO,
          FECHA: value.FECHA,
          HS_50_AUT: value.HS_50_AUT,
          HS_100_AUT: value.HS_100_AUT,
          ID_MOTIVO: value.MOTIVO_SEL,
          OBSERVACIONES: value.OBSERVACIONES_INPUT
        }
      });
      paramEntity.Json = JSON.stringify(tableResult);

      this.novedadesService.guardarRegistrosHsExtras(paramEntity).subscribe((result: ResponseHelper) => {
        if (result.Ok) {
          this._snackBar.openSnackBar('snack-success', 'Se guardó correctamente', 3000);
          this.actualizaTabla = true;
        } else {
          this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
        }
      },
        (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
    }

  }

  validarTabla(tableResult) {

    const r1: any[] = tableResult.filter((item) =>
      (Number(item.HS_50_AUT) > Number(item.HS_50_CALC)) || (Number(item.HS_100_AUT) > Number(item.HS_100_CALC))
    )
    if (r1.length) {
      const mensaje = `Las horas autorizadas no pueden ser mayor a las horas calculadas, por favor verifique el valor ingresado el día ${r1[0].FECHA}`;
      this._snackBar.openSnackBar('snack-danger', mensaje, 4000);
      return false;
    }


    const r2: any[] = tableResult.filter((item) =>
      ((Number(item.HS_50_AUT) > 0 || Number(item.HS_100_AUT) > 0) && Number(item.MOTIVO_SEL)==0)
    )
    if (r2.length) {
      const mensaje = `Las horas autorizadas requieren que seleccione algún motivo de justificación. Por favor seleccione el motivo correspondiente para el día ${r2[0].FECHA}`;
      this._snackBar.openSnackBar('snack-danger', mensaje, 4000);
      return false;
    }

    return true;
  }

  setInputEnabledTable(element, col, event) {
    if (event.target.value) {
      element.O_MOTIVO_ENABLE = 1;
    } else {
      element.O_MOTIVO_ENABLE = 0;
    }
    element[col] = event.target.value;
  }

  setInputMaxLength(col) {
    if (col=="OBSERVACIONES_INPUT") {
      return "50";

    }
    return "0";
  }

  showInputToolTip(col) {
    if (col=="OBSERVACIONES_INPUT") {
      return false;
    }
    return true;
  }

  setInputTable(element, col, event) {
    element[col] = event.target.value;
  }

  setSelectDisabled(col, element) {
    return !element.O_MOTIVO_ENABLE;
  }

  showSelectToolTip(col) {
    if (col=="MOTIVO_SEL") {
      return false;
    }
    return true;
  }


  setSelectToolTip(col, element) {
    let cmbDetalle=this.motivosCmbList.filter(x=> x.Id==element.MOTIVO_SEL).map(value => {
      return value.Detalle;
    });


    if (col=="MOTIVO_SEL") {
      return cmbDetalle;
    }
    return "";
  }

  autorizarTodas() {
    this.contentLoaded = false;
    this.dataSource.data.forEach((element) => {
      element.HS_50_AUT = element.HS_50_CALC;
      element.HS_100_AUT = element.HS_100_CALC;
    });
    this.hideSpinner();
  }

  hideSpinner() {
    setTimeout(() => {
      this.contentLoaded = true;
    }, 4000);
  }

  isSticky(col, element) {
    if (element) {

      // SE APLICA CLASE A LAS COLUMNAS FIJAS
      if (this.stickyColumns.includes(col)) {

        if ((col === 'FECHA' || col === 'DIA' || col === 'N_JORNADA') && Number(element.O_ES_FRANCO) > 0) { // ESTILO PARA LAS COLS FECHA/DIA/N_JORNADA CON CONDICION SI ES FRANCO
          return 'bg-celda-franco';
        }
        if ((col === 'FECHA' || col === 'DIA' || col === 'N_JORNADA') && Number(element.O_ES_FERIADO) > 0) { // ESTILO PARA LA COL FECHA/DIA/N_JORNADA CON CONDICION SI ES FERIADO
          return 'bg-celda-feriado';
        }
        if ((col === 'FECHA' || col === 'DIA' || col === 'N_JORNADA') && Number(element.O_ES_FERIADO) == 0 && Number(element.O_ES_FRANCO) == 0) { // ESTILO PARA LA COL FECHA/DIA/N_JORNADA CON CONDICION SI NO ES NI FRANCO
          return 'bg-celda-normal';
        }
        return 'bg-fixed-col';
      }


      if (col === 'IMPARES' && Number(element.IMPARES) > 0) { // ESTILO PARA LA COL SIN_INCIDENCIA CON CONDICION
        return 'bg-celda-anomalia';
      }
      if (col === 'SIN_INCIDENCIA' && Number(element.SIN_INCIDENCIA) > 0) { // ESTILO PARA LA COL SIN_INCIDENCIA CON CONDICION
        return 'bg-celda-anomalia';
      }
      if (col === 'HS_AUSENCIA' && Number(element.HS_AUSENCIA) > 0) {
        return 'bg-hs-ausencia';
      }

      if (col === 'MOTIVO_SEL' && (Number(element.HS_50_AUT) > 0 || Number(element.HS_100_AUT) > 0)){
        return 'bg-requiere-motivo';
      }

      if (col === 'OBSERVACIONES_INPUT' && (Number(element.HS_50_AUT) > 0 || Number(element.HS_100_AUT) > 0)){
        return 'bg-requiere-motivo';
      }

      if ((col === 'MOTIVO_SEL' || col === 'OBSERVACIONES_INPUT') && (Number(element.HS_50_AUT) == 0 || Number(element.HS_100_AUT) == 0)){
        return 'bg-no-requiere-motivo';
      }

      if (col === 'HS_FERIADO_TRAB' && Number(element.O_ES_FERIADO) > 0) { // ESTILO PARA LA COL FECHA/DIA/N_JORNADA CON CONDICION SI ES FERIADO
        return 'bg-celda-feriado_trabajado';
      }
      if (col === 'HS_FERIADO_TRAB' && Number(element.O_ES_FERIADO) == 0) { // ESTILO PARA LA COL FECHA/DIA/N_JORNADA CON CONDICION SI ES FERIADO
        return 'bg-celda-feriado_no_trabajado';
      }

      return '';

    }

    return this.stickyColumns.includes(col);
  }

  getStyle(col, element) {
    if ((col === 'HS_50_CALC' || col === 'HS_100_CALC')) {
      const colName = col.replace('CALC', 'AUT');
      return element[colName] === null ? 'hs-calc-err' : 'hs-calc-succ';
    }


    // if (col === 'IMPARES' && Number(element.IMPARES) > 0) {
    //   return 'anomalias';
    // }
    // if (col === 'SIN_INCIDENCIA' && Number(element.SIN_INCIDENCIA) > 0) {
    //   return 'anomalias';
    // }
    // if (Number(element.O_ES_FRANCO) > 0) {
    //   return 'franco';
    // }
    // if (Number(element.O_ES_FERIADO) > 0) {
    //   return 'feriado';
    // }

    // if (Number(element.O_ES_FRANCO) == 0 && Number(element.O_ES_FERIADO) == 0){
    //   return 'normal';
    // }

  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private getCmbMotivos() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario
    });

    this.novedadesService.getMotivosCmb(paramEntity).subscribe((result: any) => {
      this.motivosCmbList = result;
    });
  }

  selectOptionsArr(col) {
    switch (col) {
      case 'MOTIVO_SEL':
        return this.motivosCmbList;
      default:
        break;
    }
  }

  getSelectedOption(e, col, element) {
    switch (col) {
      case 'MOTIVO_SEL':
        break;
      default:
        break;
    }
  }

}
