import { FormControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GestionDocumental } from 'src/app/models/rrhh/gestion-documental';

@Component({
  selector: 'app-gestion-form',
  templateUrl: './gestion-form.component.html',
  styleUrls: ['./gestion-form.component.scss']
})
export class GestionFormComponent implements OnInit {
  @Input() gestionDocumental: GestionDocumental = {} as GestionDocumental;
  observacionsInput = new FormControl();
  accionSelected;
  @Output() observacionesEmit = new EventEmitter();
  @Output() accionSelectedEmit = new EventEmitter();
  @Output() guardarEmit = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.gestionDocumental) {
      this.observacionsInput.setValue(this.gestionDocumental.Observaciones);
      this.observacionesEmit.emit(this.gestionDocumental.Observaciones);
    }
    this.observacionsInput.valueChanges.subscribe((value) => {
      this.observacionesEmit.emit(value);
    });

    this.accionSelected = 1;
    this.accionSelectedEmit.emit(this.accionSelected);
  }

  getAccionSelected(e) {
    this.accionSelectedEmit.emit(e.value);
  }

  guardar() {
    this.guardarEmit.emit();
  }

}
