import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TableroCard, TableroDetail } from 'src/app/models/general/tablero-card';
import { SenderService } from 'src/app/services/utils/sender.service';
import { catchError, map } from 'rxjs/operators';
import { TableroService } from 'src/app/services/tablero.service';
import { ParamEntity } from 'src/app/models/general/param.model';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { Router } from '@angular/router';
import { datePeriodConverter, dateStrSplitter } from 'src/app/functions/date-period';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { getKeyValue } from 'src/app/functions/key-value';
import { FileService } from 'src/app/services/file.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { FileEntity } from 'src/app/models/general/file.entity';
import { ModalMarcacionComponent } from '../modals/modal-marcacion/modal-marcacion.component';
import { formatDate } from '@angular/common';
import { Novedades } from 'src/app/models/rrhh/novedades/novedades.model';
import { tcontroldetParams } from 'src/app/models/general/tcontrolDet.entity';

@Component({
  selector: 'app-detalle-tablero',
  templateUrl: './detalle-tablero.component.html',
  styleUrls: ['./detalle-tablero.component.scss']
})
export class DetalleTableroComponent implements OnInit, AfterViewInit {
  dataSended$ = new Observable<TableroDetail>();
  tableroValues = new TableroDetail();
  dataSource = new MatTableDataSource<any>([]);

  columns: string[];
  infoText: string;
  @ViewChild('detallePaginator', { static: true }) paginator: MatPaginator;
  contentLoaded = false;
  loadingFile = false;

  constructor(
    private senderService: SenderService,
    private tableroService: TableroService,
    private userValuesService: UserValuesService,
    private _snackBar: SnackBarService,
    private fileService: FileService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.dataSended$ = this.senderService.$objSourceTableroDetalle;
    this.dataSended$.subscribe((values: TableroDetail) => {
      if (values && values.TableroItem) {
        this.tableroValues = values;
        this.infoText = this.tableroValues.TableroItem.Ncontrol + ' - '
          + datePeriodConverter(this.tableroValues.TableroFilters.Periodo, true)
          + `- (Sector: ${this.tableroValues.TableroFilters.Seccion})`;
      }
    });

    if (!this.tableroValues.TableroItem) {
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getDetalle();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2500);
  }

  private getDetalle() {
    const paramEntity = new ParamEntity();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdTcontrol = this.tableroValues.TableroItem.IdControl;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.Periodo = datePeriodConverter(this.tableroValues.TableroFilters.Periodo, true);
    paramEntity.IdSeccion = this.tableroValues.TableroFilters.IdSeccion;

    //console.log(paramEntity)

    this.tableroService.getDetalleService(paramEntity).subscribe((result: any[]) => {
      if (result) {
        this.columns = getKeyValue(result)[0];
        this.dataSource.data = result;
      }
    })
  }

  filtrarGrilla(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  printFile(soloDescarga: boolean, url?, fileName?) {
    // DESCOMENTAR CUANDO HAYA QUE IMPLEMENTAR
    let params = new tcontroldetParams();
    params.idEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    params.idUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    params.periodo = datePeriodConverter(this.tableroValues.TableroFilters.Periodo, true);
    params.idSeccion = this.tableroValues.TableroFilters.IdSeccion;
    params.idTcontrol = this.tableroValues.TableroItem.IdControl;
    params.fileName = this.tableroValues.TableroItem.DownFilename;

    this.loadingFile = true; // inhabilito todos los controles del html
    // SI ES TRUE, USO TIMEOUT PARA QUE NO SE PISE CON EL MENSAJE ANTERIOR
    setTimeout(() => {
      this._snackBar.openSnackBar('snack-warning', 'Generando archivo. Por favor espere.');
    }, 1000);

    this.fileService.Excel_TControlDet(params).subscribe((resultDownload) => {
      if (resultDownload) {
        if (!soloDescarga) {
          // SI SE DESCARGA CORRECTAMENTE LIMPIO TODO Y ACTUALIZO LOS DATOS
          //console.log("Descarga Ok");
        }
        // USO EL TIMEOUT DE NUEVO PARA ELIMINAR EL MENSAJE ANTERIOR
        setTimeout(() => {
          this._snackBar.dismissSnackbar();
        }, 3000);

      } else {
        this._snackBar.openSnackBar('snack-danger', 'Hubo un error al intentar descargar el archivo', 3000);
      }
      this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    }, (errorDescarga) => {
      this._snackBar.openSnackBar('snack-danger', errorDescarga, 5000);
      this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    });
  }

  openModalData(row) {
    //DESCOMENTAR CUANDO HAYA QUE IMPLEMENTAR
    if (row.LEGAJO && row.FECHA) { // SE TOMAN LOS CAMPOS LEGAJO Y FECHA TAL CUAL LLEGAN DEL SP
      const paramEntity = new ParamEntity();
      paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
      paramEntity.IdLegajo = row.LEGAJO;
      paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
      paramEntity.FechaDate = dateStrSplitter(row.FECHA, true) as Date;

      this.tableroService.getNovedadDetalleService(paramEntity).subscribe((result: Novedades) => {
        if (result) {
          result.FechaDate = paramEntity.FechaDate; // adjunto la fecha tomada de la row
          this.showModal(result);
        } else {
          this._snackBar.openSnackBar('snack-danger', 'Intente nuevamente', 3000);
        }
      }, (error) => {
        this._snackBar.openSnackBar('snack-danger', 'Intente nuevamente', 3000);
      })

    }

  }

  private showModal(row) {
    const dialogRef = this.dialog.open(ModalMarcacionComponent, {
      width: '1000px',
      height: '500px',
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: {
        titulo: 'Novedades',
        obj: row,
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {

    });
  }

}
