import { AppModule } from './../../app.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvisosAusenciasComponent } from './avisos-ausencias/avisos-ausencias.component';
import { AutogestionRoutingModule } from './autogestion-routing.module';
import { HomeAutogestionComponent } from '../home-autogestion/home-autogestion.component';
import { MenuHomeAutogestionComponent } from './menu-home-autogestion/menu-home-autogestion.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule, MatButtonModule, MatInputModule, MatAutocompleteModule, MatFormFieldModule, MatRadioModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule, MatCardModule, MatStepperModule, MatTabsModule, MatExpansionModule, MatButtonToggleModule, MatChipsModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatTooltipModule, MatSnackBarModule, MatTableModule, MatSortModule, MatPaginatorModule, MatDatepickerModule, MatNativeDateModule, MatBadgeModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Angular2UsefulSwiperModule } from 'angular2-useful-swiper';
import { QRCodeModule } from 'angularx-qrcode';
import { ChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ViewTitleComponent } from '../autogestion/Utils/view-title/view-title.component';
import { ModalUploadComponent } from './modals/modal-upload/modal-upload.component';
import { CloseSessionButtonComponent } from './menu-home-autogestion/close-session-button/close-session-button.component';



@NgModule({
  declarations: [
    HomeAutogestionComponent,
    AvisosAusenciasComponent,
    MenuHomeAutogestionComponent,
    ViewTitleComponent,
    ModalUploadComponent,
    CloseSessionButtonComponent
  ],
  entryComponents: [
    ModalUploadComponent
  ],
  exports: [
    MenuHomeAutogestionComponent
  ],
  imports: [
    CommonModule,
    AutogestionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatMomentDateModule,
    DragDropModule,
    MatBadgeModule,
    NgxMaterialTimepickerModule.setLocale('es-AR'),
    NgxSpinnerModule,
    Angular2UsefulSwiperModule,
    ChartsModule,
    QRCodeModule,
    PdfViewerModule
  ]
})
export class AutogestionModule { }
