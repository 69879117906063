import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ParamEntity } from 'src/app/models/general/param.model';
import { AutogestionService } from 'src/app/services/rrhh/autogestion/autogestion.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';

@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss']
})
export class ModalUploadComponent implements OnInit {
  idAviso: number;
  paramEntity: ParamEntity<any>;
  urlFotoPersonal = '';
  filename = '';
  fileBase64: any;


  constructor(
    public dialogRef: MatDialogRef<ModalUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private autogestionService: AutogestionService,
    private _snackBar: SnackBarService,
  ) {
    this.idAviso = data;
  }

  ngOnInit() {
    this.filename=null;
    this.fileBase64=null;
  }

  cerrarModal(result) {
    this.dialogRef.close(result);
  }

    inputFileChange(fileInputEvent: any) {
    const file = fileInputEvent.target.files[0];
    this.filename = file.name;
    const reader = new FileReader();
    let byteArray;
    const _this = this;

    reader.addEventListener("loadend", function () {
      byteArray = _this.convertDataURIToBinary(reader.result);
      fileInputEvent.target.value = null;
      _this.fileBase64= reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  convertDataURIToBinary(dataURI) {
    let base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;
    let base64 = dataURI.substring(base64Index);
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }


  enviarArchivo(){
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdAviso: this.idAviso ,
      FileModel:{
        fileName:this.filename,
        fileBase64: this.fileBase64
      }
    });

    if(this.filename!=null && this.filename!=''){
        this.autogestionService.guardarAvisoAusencia(paramEntity).subscribe((result: ResponseHelper) => {
          let mensaje: string=null;
          if(result.Mensaje==null){
            if(result.Ok){
              mensaje ='Archivo enviado con éxito.';
            }else{
              mensaje ='Archivo no enviado.';
            }
          }else{
            mensaje = result.Mensaje;
          }


          const type = result.Ok ? 'snack-success' : 'snack-danger';
          this._snackBar.openSnackBar(type, mensaje, 3000);
          this.filename=null;
          this.fileBase64=null;
          this.cerrarModal(null);
        },(error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
    }else{
      this._snackBar.openSnackBar('snack-danger', 'Debe seleccionar un archivo para poder realizar el envío.', 3000);
    }
  }

  cancelarEnvio(){
    this.cerrarModal(null);
  }

}
