import { FilterIndividualComponent } from './components/utils/filter-individual/filter-individual.component';
import { ReportingIndividualComponent } from './components/reporting-individual/reporting-individual.component';
import { ModalInstructivoVideoComponent } from './components/modals/modal-instructivo-video/modal-instructivo-video.component';
import { ListBComponent } from './components/configuracion-usuarios/modal-configuracion-usuarios-nomina/list-b/list-b.component';
import { FileService } from './services/file.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Component, Directive } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, RouterStateSnapshot } from '@angular/router';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { pageTestComponent } from './components/utils/page-test/page-test.component';
import { NovedadesLiquidacionComponent } from './components/liquidaciones/novedades-liquidacion/novedades-liquidacion.component';
import { UserHubMonitorComponent } from './components/user-hub-monitor/user-hub-monitor.component'; 


// Angular Material Components
import { MatCheckboxModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBadgeModule } from '@angular/material/badge';


// Servicios
import { AppRoutingModule } from './app-routing.module';
import { LoginService } from './services/login/login.service';
import { LoginEndPoint } from './services/login/login-endpoint';
import { NominaService } from './services/rrhh/nomina/nomina.service';
import { NominaEndPoint } from './services/rrhh/nomina/nomina-endpoint';
import { NovedadesService } from './services/rrhh/novedades/novedades.service';
import { NovedadesEndPoint } from './services/rrhh/novedades/novedades-endpoint';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { DatepickerComponent } from './components/utils/datepicker/datepicker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TimepickerComponent } from './components/utils/timepicker/timepicker.component';
import { UserValuesService } from './services/utils/user-values.service';
import { AuthGuard } from './services/utils/auth.guard';
import { SenderService } from './services/utils/sender.service';
import { LoginComponent } from './components/login/login.component';
import { SnackBarService } from './services/utils/snackBar.service';
import { AlertComponent } from './components/utils/alert/alert/alert.component';
import { LoginIngresoAdministracionComponent } from './components/login/login-ingreso-administracion/login-ingreso-administracion.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptorService, DEFAULT_TIMEOUT } from './services/utils/loader-interceptor.service';
import { MenuNavComponent } from './components/menu-nav/menu-nav.component';
import { ModalMarcacionComponent } from './components/modals/modal-marcacion/modal-marcacion.component';
import { ParteDiarioComponent } from './components/novedades/parte-diario/parte-diario.component';
import { JornadasHabitualesComponent } from './components/novedades/jornadas-habituales/jornadas-habituales.component';
import { ModalJornadasHabitualesComponent } from './components/modals/modal-jornadas-habituales/modal-jornadas-habituales.component';
import { ModalConfirmacionComponent } from './components/modals/modal-confirmacion/modal-confirmacion.component';
import { JustificacionesComponent } from './components/novedades/justificaciones/justificaciones.component';
import { ModalNominaComponent } from './components/modals/modal-nomina/modal-nomina.component';
import { AvisosComponent } from './components/novedades/avisos/avisos.component';
import { VacacionesComponent } from './components/novedades/vacaciones/vacaciones.component';
import { DetalleTableroComponent } from './components/detalle-tablero/detalle-tablero.component';
import { TableroService } from './services/tablero.service';
import { SwiperCardsComponent } from './components/utils/swiper-cards/swiper-cards.component';
import { Angular2UsefulSwiperModule } from 'angular2-useful-swiper';
import { ImageService } from './services/utils/image.service';
import { ReportingGeneralComponent } from './components/reporting-general/reporting-general.component';
import { SelectionListComponent } from './components/reporting-general/selection-list/selection-list.component';
import { TableReportComponent } from './components/reporting-general/table-report/table-report.component';
import { FilterCardComponent } from './components/utils/filter-card/filter-card.component';
import { ReporteService } from './services/reporte.service';
import { ConfiguracionesComponent } from './components/configuraciones/configuraciones.component';
import { ConfiguracionService } from './services/configuracion.service';
import { GroupByPipe } from './pipes/groupby.pipe';
import { DetalleConfiguracionComponent } from './components/detalle-configuracion/detalle-configuracion.component';
import { ImageConfigPipe } from './pipes/image-config.pipe';
import { StylePipe } from './pipes/style.pipe';
import { ModalIncidenciasAgrupadoresComponent } from './components/modals/abm/modal-incidencias-agrupadores/modal-incidencias-agrupadores.component';
import { ModalIncidenciasComponent } from './components/modals/abm/modal-incidencias/modal-incidencias.component';
import { ModalNominaSectoresComponent } from './components/modals/abm/modal-nomina-sectores/modal-nomina-sectores.component';
import { ModalNominaRelacionesLabComponent } from './components/modals/abm/modal-nomina-relaciones-lab/modal-nomina-relaciones-lab.component';
import { ModalNominaAbmComponent } from './components/modals/abm/modal-nomina-abm/modal-nomina-abm.component';
import { ModalFeriadosComponent } from './components/modals/abm/modal-feriados/modal-feriados.component';
import { ModalUsuariosAgrupadoresComponent } from './components/modals/abm/modal-usuarios-agrupadores/modal-usuarios-agrupadores.component';
import { ModalPatologiasComponent } from './components/modals/abm/modal-patologias/modal-patologias.component';
import { ModalPasswordEnrolamientoComponent } from './components/modals/abm/modal-password-enrolamiento/modal-password-enrolamiento.component';
import { ModalSeccionesComponent } from './components/modals/abm/modal-secciones/modal-secciones.component';
import { ModalEquiposComponent } from './components/modals/abm/modal-equipos/modal-equipos.component';
import { ModalPeriodosCierresComponent } from './components/modals/abm/modal-periodos-cierres/modal-periodos-cierres.component';
import { ModalUsuariosComponent } from './components/modals/abm/modal-usuarios/modal-usuarios.component';
import { ModalDetalleLiquidacionComponent } from './components/modals/modal-detalle-liquidacion/modal-detalle-liquidacion.component';
import { ModalFormVacacionesComponent } from './components/modals/modal-form-vacaciones/modal-form-vacaciones.component';
import { HsExtrasComponent } from './components/hs-extras/hs-extras.component';
import { ModalDetalleHsExtrasComponent } from './components/modals/modal-detalle-hs-extras/modal-detalle-hs-extras.component';
import { ModalTableroControlComponent } from './components/modals/modal-tablero-control/modal-tablero-control.component';
import { ChartsModule } from 'ng2-charts';
import { JornadasProgramadasComponent } from './components/jornadas-programadas/jornadas-programadas.component';
import { ModalJornadaProgramadaDetComponent } from './components/modals/modal-jornada-programada-det/modal-jornada-programada-det.component';
import { ModalActualizacionJornadaComponent } from './components/modals/modal-actualizacion-jornada/modal-actualizacion-jornada.component';
import { MessageComponent } from './components/utils/message/message.component';
import { CalendarizadosComponent } from './components/calendarizados/calendarizados.component';
import { ConfiguracionJornadasComponent } from './components/configuracion-jornadas/configuracion-jornadas.component';
import { FormConfigAComponent } from './components/configuracion-jornadas/form-config-a/form-config-a.component';
import { FormConfigBComponent } from './components/configuracion-jornadas/form-config-b/form-config-b.component';
import { FormConfigCComponent } from './components/configuracion-jornadas/form-config-c/form-config-c.component';
import { ListJornadasComponent } from './components/configuracion-jornadas/list-jornadas/list-jornadas.component';
import { ListPlantillasComponent } from './components/configuracion-jornadas/list-plantillas/list-plantillas.component';
import { ListUsuariosPlantillaComponent } from './components/configuracion-jornadas/list-usuarios-plantilla/list-usuarios-plantilla.component';
import { ModalAbmJornadasPlantillasComponent } from './components/configuracion-jornadas/modal-abm-jornadas-plantillas/modal-abm-jornadas-plantillas.component';
import { ConfiguracionUsuariosComponent } from './components/configuracion-usuarios/configuracion-usuarios.component';
import { ListUsuariosComponent } from './components/configuracion-usuarios/list-usuarios/list-usuarios.component';
import { ListPlantillasNominaComponent } from './components/configuracion-usuarios/list-plantillas-nomina/list-plantillas-nomina.component';
import { ListNominaComponent } from './components/configuracion-usuarios/list-nomina/list-nomina.component';
import { ListPlantillasJornadasComponent } from './components/configuracion-usuarios/list-plantillas-jornadas/list-plantillas-jornadas.component';
import { ListUsrJornadasComponent } from './components/configuracion-usuarios/list-usr-jornadas/list-usr-jornadas.component';
import { PanelConfigUsuarioComponent } from './components/configuracion-usuarios/panel-config-usuario/panel-config-usuario.component';
import { ModalConfiguracionUsuariosNominaComponent } from './components/configuracion-usuarios/modal-configuracion-usuarios-nomina/modal-configuracion-usuarios-nomina.component';
import { ListAComponent } from './components/configuracion-usuarios/modal-configuracion-usuarios-nomina/list-a/list-a.component';
import { DataShareService } from './services/data-share.service';
import { ModalAbmComponent } from './components/configuracion-usuarios/modal-configuracion-usuarios-nomina/modal-abm/modal-abm.component';
import { ConfiguracionPlantillasNominaComponent } from './components/configuracion-plantillas-nomina/configuracion-plantillas-nomina.component';
import { ConfigPlantillasNominaListPlantillasComponent } from './components/configuracion-plantillas-nomina/config-plantillas-nomina-list-plantillas/config-plantillas-nomina-list-plantillas.component';
import { ConfigPlantillasNominaListNominaComponent } from './components/configuracion-plantillas-nomina/config-plantillas-nomina-list-nomina/config-plantillas-nomina-list-nomina.component';
import { ConfiguracionPlantillasJornadasComponent } from './components/configuracion-plantillas-jornadas/configuracion-plantillas-jornadas.component';
import { ConfigPlantillasJornadasListPlantillasComponent } from './components/configuracion-plantillas-jornadas/config-plantillas-jornadas-list-plantillas/config-plantillas-jornadas-list-plantillas.component';
import { ConfigPlantillasJornadasListJornadasComponent } from './components/configuracion-plantillas-jornadas//config-plantillas-jornadas-list-jornadas/config-plantillas-jornadas-list-jornadas.component';
import { NovedadesTimelineComponent } from './components/novedades-timeline/novedades-timeline.component';
import { FilterPipe } from './pipes/filter.pipe';
import { MonitorProcesosComponent } from './components/sistema/monitor-procesos/monitor-procesos.component';
import { ConfiguracionNominaComponent } from './components/configuracion-nomina/configuracion-nomina.component';
import { QRCodeModule } from 'angularx-qrcode';
import { GestionDocumentalComponent } from './components/gestion-documental/gestion-documental.component';
import { GestionCodigoComponent } from './components/gestion-documental/gestion-codigo/gestion-codigo.component';
import { GestionFormComponent } from './components/gestion-documental/gestion-form/gestion-form.component';
import { GestionMensajeComponent } from './components/gestion-documental/gestion-mensaje/gestion-mensaje.component';
import { InstructivosComponent } from './components/instructivos/instructivos.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SelectionReportIndividualListComponent } from './components/reporting-individual/selection-report-individual-list/selection-report-individual-list.component';
import { TableReportIndividualComponent } from './components/reporting-individual/table-report-individual/table-report-individual.component';
import { ModalGestionIncidenciaComponent } from './components/modals/modal-gestion-incidencia/modal-gestion-incidencia.component';
import { ConceptosEspecialesPagadosComponent } from './components/conceptos-especiales-pagados/conceptos-especiales-pagados.component';


//Directives
import { AutofocusDirective } from './directives/autofocus.directive';
import { NumberCommaDirective } from './directives/number-comma.directive';
import { ModalNominaCofarsurComponent } from './components/modals/modal-nomina-cofarsur/modal-nomina-cofarsur.component';
import { LoginSelectorComponent } from './components/login/login-selector/login-selector.component';
import { LoginIngresoAutogestionComponent } from './components/login/login-ingreso-autogestion/login-ingreso-autogestion.component';
import { HomeAutogestionComponent } from './components/home-autogestion/home-autogestion.component';
import { MenuHomeAutogestionComponent } from './components/autogestion/menu-home-autogestion/menu-home-autogestion.component';
import { AutogestionModule } from './components/autogestion/autogestion-module.module';
import { UserAutogestionService } from './services/utils/user-autogestion.service';
import { LoginAutogestionService } from './services/login/login-autogestion.service';
import { LoginAutogestionEndpointService } from './services/login/login-autogestion-endpoint';
import { AutogestionService } from './services/rrhh/autogestion/autogestion.service';
import { AutogestionEndPoint } from './services/rrhh/autogestion/autogestion-endpoint';


declare var $: any;
registerLocaleData(localeEsAr, 'es-AR');


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MenuNavComponent,
    ParteDiarioComponent,
    ModalMarcacionComponent,
    DatepickerComponent,
    TimepickerComponent,
    AlertComponent,
    LoginIngresoAdministracionComponent,
    JornadasHabitualesComponent,
    ModalJornadasHabitualesComponent,
    ModalConfirmacionComponent,
    JustificacionesComponent,
    ModalNominaComponent,
    AvisosComponent,
    AutofocusDirective,
    VacacionesComponent,
    DetalleTableroComponent,
    SwiperCardsComponent,
    ReportingGeneralComponent,
    SelectionListComponent,
    TableReportComponent,
    FilterCardComponent,
    pageTestComponent,
    NovedadesLiquidacionComponent,
    ConfiguracionesComponent,
    GroupByPipe,
    DetalleConfiguracionComponent,
    ImageConfigPipe,
    StylePipe,
    ModalIncidenciasAgrupadoresComponent,
    ModalIncidenciasComponent,
    ModalNominaSectoresComponent,
    ModalNominaRelacionesLabComponent,
    ModalNominaAbmComponent,
    ModalFeriadosComponent,
    ModalUsuariosAgrupadoresComponent,
    ModalPatologiasComponent,
    ModalPasswordEnrolamientoComponent,
    ModalSeccionesComponent,
    ModalEquiposComponent,
    ModalPeriodosCierresComponent,
    ModalUsuariosComponent,
    ModalDetalleLiquidacionComponent,
    ModalFormVacacionesComponent,
    HsExtrasComponent,
    ModalDetalleHsExtrasComponent,
    ModalTableroControlComponent,
    JornadasProgramadasComponent,
    ModalJornadaProgramadaDetComponent,
    ModalActualizacionJornadaComponent,
    MessageComponent,
    CalendarizadosComponent,
    ConfiguracionJornadasComponent,
    FormConfigAComponent,
    FormConfigBComponent,
    FormConfigCComponent,
    ListJornadasComponent,
    ListPlantillasComponent,
    ListUsuariosPlantillaComponent,
    ModalAbmJornadasPlantillasComponent,
    ConfiguracionUsuariosComponent,
    ListUsuariosComponent,
    ListPlantillasNominaComponent,
    ListNominaComponent,
    ListPlantillasJornadasComponent,
    ListUsrJornadasComponent,
    PanelConfigUsuarioComponent,
    ModalConfiguracionUsuariosNominaComponent,
    ListAComponent,
    ListBComponent,
    ModalAbmComponent,
    ConfiguracionPlantillasNominaComponent,
    ConfiguracionPlantillasJornadasComponent,
    ConfigPlantillasNominaListPlantillasComponent,
    ConfigPlantillasNominaListNominaComponent,
    ConfigPlantillasJornadasListPlantillasComponent,
    ConfigPlantillasJornadasListJornadasComponent,
    NovedadesTimelineComponent,
    FilterPipe,
    MonitorProcesosComponent,
    ConfiguracionNominaComponent,
    GestionDocumentalComponent,
    GestionCodigoComponent,
    GestionFormComponent,
    GestionMensajeComponent,
    ModalInstructivoVideoComponent,
    InstructivosComponent,
    ReportingIndividualComponent,
    FilterIndividualComponent,
    SelectionReportIndividualListComponent,
    TableReportIndividualComponent,
    ModalGestionIncidenciaComponent,
    ConceptosEspecialesPagadosComponent,
    NumberCommaDirective,
    ModalNominaCofarsurComponent,
    LoginSelectorComponent,
    LoginIngresoAutogestionComponent,
    UserHubMonitorComponent
  ],
  exports: [
    DatepickerComponent
  ],
  entryComponents: [
    ModalMarcacionComponent,
    ModalJornadasHabitualesComponent,
    ModalConfirmacionComponent,
    ModalNominaComponent,
    ModalIncidenciasAgrupadoresComponent,
    ModalIncidenciasComponent,
    ModalNominaSectoresComponent,
    ModalNominaRelacionesLabComponent,
    ModalNominaAbmComponent,
    ModalFeriadosComponent,
    ModalUsuariosAgrupadoresComponent,
    ModalPatologiasComponent,
    ModalPasswordEnrolamientoComponent,
    ModalSeccionesComponent,
    ModalEquiposComponent,
    ModalPeriodosCierresComponent,
    ModalUsuariosComponent,
    ModalDetalleLiquidacionComponent,
    ModalFormVacacionesComponent,
    ModalDetalleHsExtrasComponent,
    ModalTableroControlComponent,
    ModalJornadaProgramadaDetComponent,
    ModalActualizacionJornadaComponent,
    ModalAbmJornadasPlantillasComponent,
    ModalConfiguracionUsuariosNominaComponent,
    ModalAbmComponent,
    ConfiguracionPlantillasNominaComponent,
    ConfiguracionPlantillasJornadasComponent,
    ConfigPlantillasNominaListPlantillasComponent,
    ConfigPlantillasNominaListNominaComponent,
    ConfigPlantillasJornadasListPlantillasComponent,
    ConfigPlantillasJornadasListJornadasComponent,
    ConfiguracionNominaComponent,
    ModalInstructivoVideoComponent,
    ModalGestionIncidenciaComponent,
    ConceptosEspecialesPagadosComponent,
    ModalNominaCofarsurComponent,
    DatepickerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatMomentDateModule,
    DragDropModule,
    MatBadgeModule,
    NgxMaterialTimepickerModule.setLocale('es-AR'),
    NgxSpinnerModule,
    Angular2UsefulSwiperModule,
    ChartsModule,
    QRCodeModule,
    PdfViewerModule,
    AutogestionModule
  ],
  providers: [ // Se declaran los servicios
    LoginService,
    LoginEndPoint,
    NominaService,
    NominaEndPoint,
    NovedadesService,
    NovedadesEndPoint,
    UserValuesService,
    UserAutogestionService,
    AuthGuard,
    SenderService,
    SnackBarService,
    LoadingInterceptorService,
    FileService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true
    },
    [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }],
    TableroService,
    ImageService,
    ReporteService,
    ConfiguracionService,
    DatePipe,
    DataShareService,
    GroupByPipe,
    LoginAutogestionService,
    LoginAutogestionEndpointService,
    AutogestionService,
    AutogestionEndPoint,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


