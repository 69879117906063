import { ModalInstructivoVideoComponent } from './../../modals/modal-instructivo-video/modal-instructivo-video.component';
import { FileEntity } from './../../../models/general/file.entity';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { ModalNominaComponent } from '../../modals/modal-nomina/modal-nomina.component';
import { Nomina } from 'src/app/models/rrhh/nomina.model';
import { ModalConfirmacionComponent } from '../../modals/modal-confirmacion/modal-confirmacion.component';
import { Vacacion } from 'src/app/models/rrhh/vacacion.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { FileService } from 'src/app/services/file.service';
import { LoadingInterceptorService } from 'src/app/services/utils/loader-interceptor.service';
// import { jsPDF, jsPDFOptions } from 'jspdf';
import { ImageService } from 'src/app/services/utils/image.service';
import html2canvas, { Options } from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
import { docVacacionesModel } from 'src/app/models/rrhh/documentos/docVacaciones.model';
import domtoimage from 'dom-to-image';
// import * as jsPDF from 'jspdf';
import jsPDF from 'jspdf';
import { ModalFormVacacionesComponent } from '../../../components/modals/modal-form-vacaciones/modal-form-vacaciones.component';


@Component({
  selector: 'app-vacaciones',
  templateUrl: './vacaciones.component.html',
  styleUrls: ['./vacaciones.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VacacionesComponent implements OnInit, AfterViewInit {
  menuMarcacionOpened = false;
  startDateValue;
  endDateValue;
  vacacionesListColumns: string[] = [
    'periodo',
    'idDocumento',
    'fechaDesde',
    'fechaHasta',
    'dias',
    'estado',
    'usuario',
    'fechaRegistro',
    'estadoDocumento',
    'observaciones',
    'accion'
  ];

  saldosVacacionesListColumns: string[] = [
    'periodo',
    'diasDevengados',
    'diasPercibidos',
    'saldoPeriodo'
  ];
  vacacionesListDataSource = new MatTableDataSource<Vacacion>([]);
  @ViewChild('PaginatorOtorgamientos',{ static: true }) set matPaginatorList(paginatorVacList: MatPaginator) {
    this.vacacionesListDataSource.paginator = paginatorVacList;
  }

  saldosVacacionesListDataSource = new MatTableDataSource<Vacacion>([]);
  @ViewChild('PaginatorSaldo', { static: true }) set matPaginatorSaldos(paginatorSaldosList: MatPaginator) {
    this.saldosVacacionesListDataSource.paginator = paginatorSaldosList;
  }
  listVacacionesCmb: CmbEntity[] = [];
  listPatologiasCmb: CmbEntity[] = [];
  fechaCalculadaRetorno = '';
  fechaCalculadaHasta = '';
  vacacionesList: Vacacion[] = [];
  dateInput: any = '';
  fechaPicker: Date;
  cmbPatologiaHabilitado: boolean;
  vacacionObj: Vacacion;
  loadingFile = false;

  constructor(
    private userValuesService: UserValuesService,
    private novedadesService: NovedadesService,
    private fileService: FileService,
    private dialog: MatDialog,
    private _snackBar: SnackBarService,
    private loadingInterceptorService: LoadingInterceptorService,
    private imageService: ImageService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.vacacionObj = new Vacacion();
  }

  ngAfterViewInit() {
  }

  toggleMenuOpened() {
    this.menuMarcacionOpened = !this.menuMarcacionOpened;
  }

  openModalNomina() {
    this.vacacionObj = new Vacacion();
    const dialogRef = this.dialog.open(ModalNominaComponent, {
      width: '1000px',
      height: '550px',
      panelClass: 'modal-marcacion',
      autoFocus: false
    });
    dialogRef.beforeClosed().subscribe((result: Nomina) => {
      if (result) {
        Object.assign(this.vacacionObj, result);
        this.dataRefresh();
      }
    });
  }


  openModalInstructivo() {
    // this.vacacionObj = new Vacacion();
    const dialogRef = this.dialog.open(ModalInstructivoVideoComponent, {
      panelClass: 'modal-instructivo',
      autoFocus: false
    });
    // dialogRef.beforeClosed().subscribe((result: any) => {
    //   if (result) {
    //     Object.assign(this.vacacionObj, result);
    //     this.dataRefresh();
    //   }
    // });
  }

  dataRefresh() {
    this.getVacacionesList();
    this.getSaldosVacacionesList();
  }

  getSeleccionIncidencia(e) {
    this.vacacionObj.IdIncidencia = e.value;
    this.setMostrarCmbPatologia();
  }

  getSeleccionPatologia(e) {
    this.vacacionObj.IdPatologia = e.value;
  }

  setMostrarCmbPatologia() {
    this.cmbPatologiaHabilitado = this.vacacionObj.IdIncidencia && this.listVacacionesCmb.find(x => x.Id === this.vacacionObj.IdIncidencia).IdPreSet === 1;
  }

  getStartDatePickerEmit(value?) {
    this.fechaPicker = value;
    this.calculaFecha(this.fechaPicker);
  }

  calculaFecha(fechaPicker?) {
    fechaPicker = (!fechaPicker) ? this.fechaPicker : fechaPicker;
    if (this.vacacionObj.Dias > 0 && this.vacacionObj.Dias < 1000) {
      const result = new Date(fechaPicker);
      result.setDate(result.getDate() + Number(this.vacacionObj.Dias));
      this.fechaCalculadaRetorno = result.toISOString();
      result.setDate(result.getDate() - 1);
      this.fechaCalculadaHasta = result.toISOString();
      return;
    }
    if (this.vacacionObj.Dias === 0) {
      this._snackBar.openSnackBar('snack-danger', 'Debe ingresar un número superior a 0 (cero)', 3000);
      return;
    }
    this.fechaCalculadaRetorno = '';
    this.fechaCalculadaHasta = '';
    this.vacacionObj.Dias = null;
  }

  soloNumeros(e) {
    if (!(e.keyCode === 8
      || e.keyCode === 46
      || (e.keyCode >= 35 && e.keyCode <= 40)
      || (e.keyCode >= 48 && e.keyCode <= 57)
      || (e.keyCode >= 96 && e.keyCode <= 105)
      || (e.keyCode === 86 && e.ctrlKey === true)
      || e.keyCode === 111)) {
      e.preventDefault();
    }
  }

  openModalConfirmacion() {
    if (this.validarCampos()) {
      const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
        width: '500px',
        height: '120px',
        autoFocus: false,
        data: {
          titulo: '¿Desea guardar?'
        }
      });
      dialogRef.beforeClosed().subscribe((result) => {
        if (result) {
          this.guardarRegistro();
        }
      });
    }
  }

  private validarCampos() {
    if (!this.vacacionObj.Dias) {
      this._snackBar.openSnackBar('snack-danger', 'Ingrese cantidad de días', 3000);
      return false;
    }
    return true;
  }

  private getVacacionesList() {
    const paramEntity = new ParamEntity<any>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdLegajo = this.vacacionObj.IdLegajo;

    this.novedadesService.getVacacionesList(paramEntity).subscribe((result: Vacacion[]) => {
      this.vacacionesListDataSource.data = result;
    });
  }

  private getSaldosVacacionesList() {
    const paramEntity = new ParamEntity<any>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdLegajo = this.vacacionObj.IdLegajo;

    this.novedadesService.getSaldosVacacionesList(paramEntity).subscribe((result: Vacacion[]) => {
      this.saldosVacacionesListDataSource.data = result;
    });
  }

  guardarRegistro() {
    const paramEntity = new ParamEntity<Vacacion>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.GenericEntity = this.vacacionObj;
    paramEntity.GenericEntity.Anulacion = true;
    paramEntity.GenericEntity.IdEstado = 1;
    paramEntity.GenericEntity.IdJustificacion = 0;
    paramEntity.GenericEntity.FechaDesde = this.fechaPicker;
    paramEntity.GenericEntity.Observaciones = '';

    this.novedadesService.updVacacion(paramEntity).subscribe((resultGuardado: ResponseHelper) => {
      // VALIDO SI EL OK ES TRUE MUESTRO EL EXITO SINO ERROR
      this._snackBar.openSnackBar((resultGuardado.Ok) ? 'snack-success' : 'snack-danger', resultGuardado.Mensaje, 3000);
      if (resultGuardado.Ok) {
        this.dataRefresh();
        this.limpiarObjeto();
        // this.printFile(false, resultGuardado.Url, resultGuardado.Downfilename);
      }
    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
  }

  anularRegistro(row: Vacacion, tipo: string) {
    const paramEntity = new ParamEntity<Vacacion>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.GenericEntity = new Vacacion();
    paramEntity.GenericEntity.IdJustificacion = row.IdJustificacion;
    paramEntity.GenericEntity.IdEstado = 0;
    paramEntity.GenericEntity.Observaciones = '';
    paramEntity.GenericEntity.FechaDesde = new Date();
    paramEntity.IdLegajo = this.vacacionObj.IdLegajo;

    this.novedadesService.updVacacion(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', result.Mensaje, 3000);
        this.dataRefresh();
        this.limpiarObjeto();
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }
    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
  }

  limpiarObjeto() {
    const nuevaVacacion = new Vacacion();
    Object.assign(nuevaVacacion, this.vacacionObj);
    this.vacacionObj = new Vacacion();
    this.vacacionObj.IdLegajo = nuevaVacacion.IdLegajo;
    this.vacacionObj.Nombre = nuevaVacacion.Nombre;
    this.vacacionObj.Apellido = nuevaVacacion.Apellido;
    this.vacacionObj.Seccion = nuevaVacacion.Seccion;
    this.vacacionObj.AntiguedadStr = nuevaVacacion.AntiguedadStr;
    this.dateInput = this.dateInput = new Date();
    this.calculaFecha(this.fechaPicker);
  }


  openModalFormVacaciones(row: Vacacion) {
    const dialogRef = this.dialog.open(ModalFormVacacionesComponent, {
      width: '2480px',
      height: '500px',
      autoFocus: false,
      data: {
        idDocumento: row.idDocumento
      }
    });

  }

}


