import { SourceJornadas } from './../../models/sources/sourceJornadas';
import { SourcePlantillasJornadas } from './../../models/sources/sourcePlantillasJornadas';
import { SourcePlantillasNomina } from './../../models/sources/sourcePlantillasNomina';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { SourceEstados } from './../../models/sources/sourceEstados';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalConfiguracionUsuariosNominaComponent } from './modal-configuracion-usuarios-nomina/modal-configuracion-usuarios-nomina.component';
import { MatDialog, MatListOption, MatSelectionList } from '@angular/material';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ParamEntity } from 'src/app/models/general/param.model';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { Usuario } from 'src/app/models/general/usuario.model';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { ConfiguracionUsuarios } from 'src/app/models/rrhh/configuracion-usuarios';
import { SelectionModel } from '@angular/cdk/collections';
import { SourceUsuariosRoles } from 'src/app/models/sources/sourceUsuariosRoles';
import { SourceUsuarios } from 'src/app/models/sources/sourceUsuarios';
import { SourceEmpleados } from 'src/app/models/sources/sourceEmpleados';
import { ListNominaComponent } from './list-nomina/list-nomina.component';
import { ListPlantillasNominaComponent } from './list-plantillas-nomina/list-plantillas-nomina.component';
import { ListUsuariosComponent } from './list-usuarios/list-usuarios.component';
import { ListUsrJornadasComponent } from './list-usr-jornadas/list-usr-jornadas.component';
import { ListPlantillasJornadasComponent } from './list-plantillas-jornadas/list-plantillas-jornadas.component';
import { ModalConfirmacionComponent } from '../modals/modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-configuracion-usuarios',
  templateUrl: './configuracion-usuarios.component.html',
  styleUrls: ['./configuracion-usuarios.component.scss']
})
export class ConfiguracionUsuariosComponent implements OnInit {
  usuario: Usuario;
  configuracionUsuariosSources = new ConfiguracionUsuarios();
  rolOptions: SourceUsuariosRoles[];
  estadoOptions: SourceEstados[];
  idUsuarioPorPlantilla: number;
  idPlantillaNomina: number;
  idPlantillaJornada: number;
  idLegajosUsuariosNomina: number[];
  idUsuarioJornadas: number[];
  paramEntity = new ParamEntity();
  clearForms = false;
  userNew = true;
  idUsuarioSelected: number;
  formUsuarios: FormGroup;
  inputFiltroUsuario = new FormControl();
  inputFiltroPlantillasNomina = new FormControl();
  inputFiltroEmpleados = new FormControl();
  inputFilterPlantillaJornadas = new FormControl();
  inputFiltroJornadas = new FormControl();

  // SOURCES
  sourceUsuariosFiltered: SourceUsuarios[] = [];
  sourcePlantillasNominaFiltered: SourcePlantillasNomina[] = [];
  sourceEmpleadosFiltered: SourceEmpleados[] = [];
  sourcePlantillasJornadasFiltered: SourcePlantillasJornadas[] = [];
  sourceJornadasFiltered: SourceJornadas[] = [];


  @ViewChild(ListUsuariosComponent, { static: false }) listUsuariosComponent: ListUsuariosComponent;
  @ViewChild(ListNominaComponent, { static: false }) listNominaComponent: ListNominaComponent;
  @ViewChild(ListPlantillasNominaComponent, { static: false }) listPlantillasNominaComponent: ListPlantillasNominaComponent;
  @ViewChild(ListUsrJornadasComponent, { static: false }) listUsrJornadasComponent: ListUsrJornadasComponent;
  @ViewChild(ListPlantillasJornadasComponent, { static: false }) listPlantillasJornadasComponent: ListPlantillasJornadasComponent;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private userValuesService: UserValuesService,
    private configuracionService: ConfiguracionService,
    private _snackBar: SnackBarService
  ) {
    this.usuario = this.userValuesService.getUsuarioValues;
    this.paramEntity.IdPlantillasJornada = [];
    this.paramEntity.IdPlantillasNomina = [];
  }

  ngOnInit() {
    this.datosRefresh();
    this.subscribeInputs();
  }

  datosRefresh() {
    this.createFormUsuarios();
    this.getConfiguracionUsuariosSources();

  }

  private subscribeInputs() {
    this.inputFiltroUsuario.valueChanges.subscribe((value) => {
      if (value) {
        this.sourceUsuariosFiltered = this.configuracionUsuariosSources.SourceUsuarios.filter(x =>
          (x.IdUsuario + ' - ' + x.Nusuario).toLowerCase().includes(value)
        );

      if(this.sourceUsuariosFiltered.length==0){
        this.nuevoUsuario();
      }

      } else {
        this.sourceUsuariosFiltered = this.configuracionUsuariosSources.SourceUsuarios;
      }
    });

    this.inputFiltroPlantillasNomina.valueChanges.subscribe((value) => {
      if (value) {
        this.sourcePlantillasNominaFiltered = this.configuracionUsuariosSources.SourcePlantillasNomina.filter(x =>
          (x.NplantillaNomina).toLowerCase().includes(value)
        );
      } else {
        this.sourcePlantillasNominaFiltered = this.configuracionUsuariosSources.SourcePlantillasNomina;
      }
    });

    this.inputFiltroEmpleados.valueChanges.subscribe((value) => {
      if (value) {
        this.sourceEmpleadosFiltered = this.configuracionUsuariosSources.SourceEmpleados.filter(x =>
          (x.IdLegajo + ' - ' + x.Nombre + ' ' + x.Apellido).toLowerCase().includes(value)
        );
      } else {
        this.sourceEmpleadosFiltered = this.configuracionUsuariosSources.SourceEmpleados;
      }
    });

    this.inputFilterPlantillaJornadas.valueChanges.subscribe((value) => {
      if (value) {
        this.sourcePlantillasJornadasFiltered = this.configuracionUsuariosSources.SourcePlantillasJornadas.filter(x =>
          (x.NplantillaJornada).toLowerCase().includes(value)
        );
      } else {
        this.sourcePlantillasJornadasFiltered = this.configuracionUsuariosSources.SourcePlantillasJornadas;
      }
    });

    this.inputFiltroJornadas.valueChanges.subscribe((value) => {
      if (value) {
        this.sourceJornadasFiltered = this.configuracionUsuariosSources.SourceJornadas.filter(x =>
          (x.Njornada).toLowerCase().includes(value)
        );
      } else {
        this.sourceJornadasFiltered = this.configuracionUsuariosSources.SourceJornadas;
      }
    });
  }


  private getConfiguracionUsuariosSources() {
    const paramEntity = new ParamEntity();
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    setTimeout(() => {
      this.configuracionService.getSourceConfiguracionUsuarios(paramEntity).subscribe((result: ConfiguracionUsuarios) => {
        this.configuracionUsuariosSources = result;
        this.rolOptions = this.configuracionUsuariosSources.SourceUsuariosRoles;
        this.estadoOptions = this.configuracionUsuariosSources.SourceEstados;
        this.sourceUsuariosFiltered = this.configuracionUsuariosSources.SourceUsuarios;
        this.sourcePlantillasNominaFiltered = this.configuracionUsuariosSources.SourcePlantillasNomina;
        this.sourceEmpleadosFiltered = this.configuracionUsuariosSources.SourceEmpleados;
        this.sourcePlantillasJornadasFiltered = this.configuracionUsuariosSources.SourcePlantillasJornadas;
        this.sourceJornadasFiltered = this.configuracionUsuariosSources.SourceJornadas;
        this.listPlantillasNominaComponent.plantillaxUsuarioList=this.configuracionUsuariosSources.SourceUsuariosXplantillaNomina;
        this.listPlantillasJornadasComponent.plantillaxUsuarioList=this.configuracionUsuariosSources.SourceUsuariosXplantillaJornada;


        this.nuevoUsuario();
      });
    }, 1000);
  }

  getUsuarioSelected(usuarioSelected: SourceUsuarios) {
    if (usuarioSelected) {
      this.userNew = false;
      this.idUsuarioSelected = usuarioSelected.IdUsuario;
      this.idUsuarioPorPlantilla = usuarioSelected.IdUsuario;
      this.getIdPlantillasFromUsuario();

      this.formUsuarios.controls.nombreUsuario.setValue(usuarioSelected.Nusuario);
      this.formUsuarios.controls.claveSeguridad.setValue(usuarioSelected.Password);
      this.formUsuarios.controls.email.setValue(usuarioSelected.Mail);
      this.formUsuarios.controls.rol.setValue(usuarioSelected.IdRol);
      this.formUsuarios.controls.estado.setValue(usuarioSelected.IdEstado);

      this.idUsuarioJornadas = [];
      this.idUsuarioJornadas = this.configuracionUsuariosSources.SourceUsuariosXjornada.filter(x => x.IdUsuario === usuarioSelected.IdUsuario).map(x => x.IdJornada);

      this.idLegajosUsuariosNomina = [];
      this.idLegajosUsuariosNomina = this.configuracionUsuariosSources.SourceUsuariosXempleados.filter(x => x.IdUsuario === usuarioSelected.IdUsuario).map(x => x.IdLegajo);
      this.listPlantillasNominaComponent.setCheckValues(this.idUsuarioSelected);
      this.listPlantillasJornadasComponent.setCheckValues(this.idUsuarioSelected);
      this.paramEntity.IdUsuario = usuarioSelected.IdUsuario;
    } else {
      this.userNew = true;
      this.idUsuarioSelected = null;
      this.idPlantillaNomina = -1;
      this.idPlantillaJornada = -1;
      this.idLegajosUsuariosNomina = [];
      this.idUsuarioJornadas = [];
      this.paramEntity = new ParamEntity();
      this.listPlantillasNominaComponent.clearForms();

      this.formUsuarios.reset();

    }
  }


  // openModalPlantillasNomina() {
  //   const dialogRef = this.dialog.open(ModalConfiguracionUsuariosNominaComponent, {
  //     width: '800px',
  //     height: '500px',
  //     autoFocus: false,
  //     data: {
  //     }
  //   });
  //   dialogRef.beforeClosed().subscribe((result) => {
  //     if (result) {
  //       //console.log(result)
  //     }
  //   });
  // }

  private createFormUsuarios() {
    this.userNew = true;
    this.formUsuarios = this.formBuilder.group({
      nombreUsuario: [''],
      claveSeguridad: [''],
      email: [],
      rol: [2],
      estado: [1]
    });
  }

  submitForm(accion?: string) {
    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '500px',
      height: '120px',
      autoFocus: false,
      data: {
        titulo: accion=='Eliminar'?'¿Está seguro que desea anular el usuario seleccionado?':'¿Desea gardar los cambios realizados?'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.validarDatos()) {
          this.paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
          this.paramEntity.IdUsuario = this.usuario.IdUsuario;
          this.paramEntity.Json = JSON.stringify({
            accion: accion=='Eliminar'?'Eliminar':'-',
            IdUsuario: this.userNew ? 0 : this.idUsuarioSelected,
            Nusuario: this.formUsuarios.controls.nombreUsuario.value,
            Password: this.formUsuarios.controls.claveSeguridad.value,
            Mail: this.formUsuarios.controls.email.value,
            IdRol: this.formUsuarios.controls.rol.value,
            IdEstado: this.formUsuarios.controls.estado.value,
            IdPlantillasNomina: this.listPlantillasNominaComponent.listPlantillaChecked,
            IdPlantillasJornada: this.listPlantillasJornadasComponent.listPlantillaChecked
          });

          this.configuracionService.postAbmUsuarios(this.paramEntity).subscribe((result: ResponseHelper) => {
            if (result.Ok) {
              this._snackBar.openSnackBar('snack-success', accion=='Eliminar'?'Usuario anulado':'Se guardó correctamente', 3000);
              this.datosRefresh();
              this.getIdPlantillasFromUsuario();
            }
          });
        }

      }
    })

  }

  private validarDatos() {
    if (this.formUsuarios.controls.nombreUsuario.value == null || this.formUsuarios.controls.nombreUsuario.value.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar un nombre de usuario.');
      return false;
    }

    if (this.formUsuarios.controls.claveSeguridad.value == null || this.formUsuarios.controls.claveSeguridad.value.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar una clave de seguridad.');
      return false;
    }

    return true;
  }

  private mostrarMensajeValidacion(mensaje: string) {
    this._snackBar.openSnackBar('snack-danger', mensaje, 5000);
  }



  private getIdPlantillasFromUsuario() {
    this.idPlantillaNomina = -1;
    this.idPlantillaJornada = -1;

    this.idPlantillaNomina = this.configuracionUsuariosSources.SourceUsuariosXplantillaNomina.find(x => x.IdUsuario === this.idUsuarioPorPlantilla) ?
      this.configuracionUsuariosSources.SourceUsuariosXplantillaNomina.find(x => x.IdUsuario === this.idUsuarioPorPlantilla).IdPlantillaNomina : -1;

    this.idPlantillaJornada = this.configuracionUsuariosSources.SourceUsuariosXplantillaJornada.find(x => x.IdUsuario === this.idUsuarioPorPlantilla) ?
      this.configuracionUsuariosSources.SourceUsuariosXplantillaJornada.find(x => x.IdUsuario === this.idUsuarioPorPlantilla).IdPlantillaJornada : -1;
  }

  compareObjectsRol(optionA, optionB) {
    if (optionA === optionB) {
      return true;
    }
    return false;
  }

  compareObjectsEstado(optionA, optionB) {
    if (optionA === optionB) {
      return true;
    }
    return false;
  }

  /*------------GET IDS-----------------*/

  getIdPlantillaNominaSelected(ids: number[]) {
    this.paramEntity.IdPlantillasNomina = ids;
  }

  getIdPlantillaJornadaSelected(ids: number[]) {
    this.paramEntity.IdPlantillasJornada = ids;
  }



  nuevoUsuario() {
    this.userNew = true;
    this.listUsuariosComponent.clearForms();
    this.listNominaComponent.clearForms();
    this.listPlantillasNominaComponent.clearForms();
    this.listUsrJornadasComponent.clearForms();
    this.listPlantillasJornadasComponent.clearForms();
    this.paramEntity = new ParamEntity();
    this.formUsuarios.reset();
    this.createFormUsuarios();
    this.idPlantillaNomina = -1;
    this.idPlantillaJornada = -1;
  }


  filtroUsuarioChange(e) {

  }




}
