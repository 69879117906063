import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatSelectionList } from '@angular/material';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Usuario } from 'src/app/models/general/usuario.model';
import { ConfiguracionUsuarios } from 'src/app/models/rrhh/configuracion-usuarios';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { SourceEmpleados } from 'src/app/models/sources/sourceEmpleados';
import { SourcePlantillasNomina } from 'src/app/models/sources/sourcePlantillasNomina';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalConfirmacionComponent } from '../modals/modal-confirmacion/modal-confirmacion.component';
import { ConfigPlantillasNominaListNominaComponent } from './config-plantillas-nomina-list-nomina/config-plantillas-nomina-list-nomina.component';
import { ConfigPlantillasNominaListPlantillasComponent } from './config-plantillas-nomina-list-plantillas/config-plantillas-nomina-list-plantillas.component';

@Component({
  selector: 'app-configuracion-plantillas-nomina',
  templateUrl: './configuracion-plantillas-nomina.component.html',
  styleUrls: ['./configuracion-plantillas-nomina.component.scss']
})
export class ConfiguracionPlantillasNominaComponent implements OnInit {
  usuario: Usuario;
  configuracionUsuariosSources = new ConfiguracionUsuarios();
  formData: FormGroup;
  newValue: boolean = true;
  paramEntity = new ParamEntity();
  idEmpleados: number[];
  formUsuarios: FormGroup;
  inputFiltroPlantillasNomina = new FormControl();
  inputFiltroEmpleados = new FormControl();
  idPlantillaNomina: number = null;

  // SOURCES
  sourcePlantillasNominaFiltered: SourcePlantillasNomina[] = [];
  sourceEmpleadosFiltered: SourceEmpleados[] = [];


  @ViewChild(ConfigPlantillasNominaListNominaComponent, { static: false }) configPlantillasNominaListNominaComponent: ConfigPlantillasNominaListNominaComponent;
  @ViewChild(ConfigPlantillasNominaListPlantillasComponent, { static: false }) configPlantillasNominaListPlantillasComponent: ConfigPlantillasNominaListPlantillasComponent;


  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private userValuesService: UserValuesService,
    private configuracionService: ConfiguracionService,
    private _snackBar: SnackBarService
  ) {
    this.usuario = this.userValuesService.getUsuarioValues;
    this.paramEntity.IdPlantillasJornada = [];
    this.paramEntity.IdPlantillasNomina = [];
  }

  ngOnInit() {
    this.datosRefresh();
    // this.subscribeInputs();
  }

  private getConfiguracionSources() {
    const paramEntity = new ParamEntity();
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    setTimeout(() => {
      this.configuracionService.getSourceConfPlantillasNomina(paramEntity).subscribe((result: ConfiguracionUsuarios) => {
        this.configuracionUsuariosSources = result;
        this.sourcePlantillasNominaFiltered = this.configuracionUsuariosSources.SourcePlantillasNomina;
        this.sourceEmpleadosFiltered = this.configuracionUsuariosSources.SourceEmpleados;

        this.configPlantillasNominaListPlantillasComponent.dataSourceList = this.sourcePlantillasNominaFiltered;
        this.configPlantillasNominaListNominaComponent.dataSourceList = this.sourceEmpleadosFiltered;
        this.configPlantillasNominaListNominaComponent.EmpleadosXplantillaList = this.configuracionUsuariosSources.SourcePlantillasNominaXempleados;
      });
    }, 1000);
  }

  datosRefresh() {
    this.createForm();
    this.getConfiguracionSources();

  }

  getPlantillaSelected(dataSelected: SourcePlantillasNomina) {
    this.newValue = false;
    if (dataSelected) {
      this.idPlantillaNomina = dataSelected.IdPlantillaNomina;
      this.formData.controls.nombre.setValue(dataSelected.NplantillaNomina);
      this.configPlantillasNominaListNominaComponent.setCheckValues(dataSelected.IdPlantillaNomina)
    } else {
      this.clearForm();
    }
  }

  nuevaPlantilla() {
    this.clearForm();
  }

  clearForm() {
    this.idPlantillaNomina = null;
    this.formData.controls.nombre.setValue('');
    this.configPlantillasNominaListNominaComponent.clearForms();
    this.configPlantillasNominaListPlantillasComponent.clearForms();
    this.newValue = true;
  }


  getOptionSelected(e) {
  }


  private createForm() {
    this.newValue = true;
    this.formData = this.formBuilder.group({
      nombre: [''],
    });
  }


  async submitForm(accion?: string) {
       const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
          width: '500px',
          height: '200px',
          autoFocus: false,
          data: {
            titulo: accion=='Eliminar'?'¿Está seguro que desea eliminar la plantilla seleccionada?':'¿Desea gardar los cambios realizados?'
          }
      });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            if (this.validarDatos()) {
              this.paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
              this.paramEntity.IdUsuario = this.usuario.IdUsuario;
              this.paramEntity.Json = JSON.stringify({
                nombrePlantilla: this.formData.controls.nombre.value,
                IdPlantillaNomina: this.idPlantillaNomina==null?0:this.idPlantillaNomina,
                IdsEmpleados: this.configPlantillasNominaListNominaComponent.listEmpleadosChecked,
                accion: accion=='Eliminar'?'Eliminar':'-'
              });

              this.configuracionService.postAbmPlantillasNomina(this.paramEntity).subscribe((result: ResponseHelper) => {
                if (result.Ok) {
                  this._snackBar.openSnackBar('snack-success', accion=='Eliminar'?'Plantilla eliminada':'Se guardó correctamente', 3000);
                  this.datosRefresh();
                }
              });
            }

          }
      })
  };

  validarDatos() {
    if (this.formData.controls.nombre.value == null || this.formData.controls.nombre.value.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar un nombre de plantilla.');
      return false;
    }
    return true;
  }

  private mostrarMensajeValidacion(mensaje: string) {
    this._snackBar.openSnackBar('snack-danger', mensaje, 5000);
  }




  getIdEmpleadoSelected(ids: number[]) {
    // //console.log(ids);
    // this.paramEntity = ids;
  }

}
