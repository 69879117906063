import { GroupByPipe } from './../../pipes/groupby.pipe';
import { ResponseHelper } from './../../models/sistema/responseHelper';
import { ParamEntity } from './../../models/general/param.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material';
import { FileEntity, FileInfo, FileTypesEnum } from 'src/app/models/general/file.entity';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-instructivos',
  templateUrl: './instructivos.component.html',
  styleUrls: ['./instructivos.component.scss']
})
export class InstructivosComponent implements OnInit {
  files: FileEntity[] = [];

  fileSelected: FileInfo = {} as FileInfo;
  fileTypesEnum = FileTypesEnum;
  defaultSelected = -1;
  selected: number;

  constructor(
    private novedadesService: NovedadesService,
    private userValuesService: UserValuesService,
    private groupByPîpe: GroupByPipe
  ) { }

  ngOnInit() {
    this.getInstructivos();
  }

  getInstructivos() {
    const params = new ParamEntity();
    params.Json = JSON.stringify(
      { "pIdUsuario": this.userValuesService.getUsuarioValues.IdUsuario }
    );
    this.novedadesService.getInstructivos(params).subscribe((res: ResponseHelper) => {
      if (res.Ok) {
        this.files = res.DataResponse;
        const files = this.groupByPîpe.transform(res.DataResponse, 'NCategoria');
        this.files = files.map(x => ({ NCategoria: x.key, IdCategoria: x.value[0].IdCategoria, FilesList: x.value }));
      }
    })
  }

  displayFile(e) {
    this.fileSelected = e.value;
    if (this.fileSelected.Filetype === this.fileTypesEnum.VIDEO) {
      setTimeout(() => {
        (<HTMLMediaElement>document.getElementById("video")).load();
      }, 500);
    }
  }

}
