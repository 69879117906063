import { Component, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Usuario } from 'src/app/models/general/usuario.model';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { Router } from '@angular/router';
import { UserHubService } from 'src/app/services/utils/user-hub.service';


@Component({
  selector: 'app-login-ingreso-administracion',
  templateUrl: './login-ingreso-administracion.component.html',
  styleUrls: ['./login-ingreso-administracion.component.scss']
})
export class LoginIngresoAdministracionComponent implements OnInit, AfterViewInit {
  nomUsuarioFormControl = new FormControl('');
  passFormControl = new FormControl('');
  usuario = new Usuario();
  mostrarProgressBar = false;

  private hubConnection: any; 

  /*--------Styles---------*/
  matCardLogin = 'mat-card-login d-none';

  @Output() setRecuperaCuenta = new EventEmitter();
  @Output() toggleSpinnerEmit = new EventEmitter();
  @Output() volverEmit = new EventEmitter();

  constructor(
    private userValuesService: UserValuesService,
    private _snackBar: SnackBarService,
    private router: Router,
    private _userHubService: UserHubService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.matCardLogin = '';
    }, 1200);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.toggleSpinnerEmit.emit(false);
    }, 500);
  }

  async loginSubmit() {
    this.mostrarProgressBar = this.validarForm();
  
    // Realizar el inicio de sesión
    const result = await this.userValuesService.setLogin(this.usuario.NomUsuario, this.usuario.Pass);
  
    if (result) {
      try {
        // Iniciar la conexión al hub SignalR en el caso de un inicio de sesión exitoso
        await this._userHubService.startConnection(this.usuario.NomUsuario);
        console.log('Conexión establecida con el UserHub SignalR');

        // Mensaje de bienvenida
        this._snackBar.openSnackBar('snack-success', 'Bienvenido ' + this.userValuesService.getUsuarioValues.NomUsuario + '!', 4000);
    
        this.mostrarProgressBar = false;
      } catch (err) {
        console.error('Error al conectar al hub SignalR', err);
      }
    } else {
      this._snackBar.openSnackBar('snack-danger', this.userValuesService.getUsuarioValues.Mensaje, 5000);
      this.mostrarProgressBar = false;
    }
  }
  

  validarForm() {
    if (!this.usuario.NomUsuario) {
      this._snackBar.openSnackBar('snack-danger', 'Debe ingresar un nombre de usuario', 4000);
      return false;
    }
    if (!this.usuario.Pass) {
      this._snackBar.openSnackBar('snack-danger', 'Debe ingresar una contraseña', 4000);
      return false;
    }
    return true;
  }

  setRecuperarCuenta() {
    this.matCardLogin = 'd-none';
    this.setRecuperaCuenta.emit();
  }

  volver() {
    this.volverEmit.emit();
  }

}
