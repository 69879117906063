import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckbox, MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { TipoConfiguracionEnum } from 'src/app/enums/configuracion.enum';
import { getAbmDataElements } from 'src/app/functions/abm-modal-data';
import { getKeyValue } from 'src/app/functions/key-value';
import { HtmlComponent } from 'src/app/models/general/html-component.interface';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalEquiposComponent } from '../modals/abm/modal-equipos/modal-equipos.component';
import { ModalFeriadosComponent } from '../modals/abm/modal-feriados/modal-feriados.component';
import { ModalIncidenciasAgrupadoresComponent } from '../modals/abm/modal-incidencias-agrupadores/modal-incidencias-agrupadores.component';
import { ModalIncidenciasComponent } from '../modals/abm/modal-incidencias/modal-incidencias.component';
import { ModalNominaAbmComponent } from '../modals/abm/modal-nomina-abm/modal-nomina-abm.component';
import { ModalNominaRelacionesLabComponent } from '../modals/abm/modal-nomina-relaciones-lab/modal-nomina-relaciones-lab.component';
import { ModalNominaSectoresComponent } from '../modals/abm/modal-nomina-sectores/modal-nomina-sectores.component';
import { ModalPasswordEnrolamientoComponent } from '../modals/abm/modal-password-enrolamiento/modal-password-enrolamiento.component';
import { ModalPatologiasComponent } from '../modals/abm/modal-patologias/modal-patologias.component';
import { ModalPeriodosCierresComponent } from '../modals/abm/modal-periodos-cierres/modal-periodos-cierres.component';
import { ModalSeccionesComponent } from '../modals/abm/modal-secciones/modal-secciones.component';
import { ModalUsuariosAgrupadoresComponent } from '../modals/abm/modal-usuarios-agrupadores/modal-usuarios-agrupadores.component';
import { ModalUsuariosComponent } from '../modals/abm/modal-usuarios/modal-usuarios.component';

@Component({
  selector: 'app-detalle-configuracion',
  templateUrl: './detalle-configuracion.component.html',
  styleUrls: ['./detalle-configuracion.component.scss']
})
export class DetalleConfiguracionComponent implements OnInit, OnDestroy {
  paramEntity = new ParamEntity<any>();
  configuracion: Configuracion;
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild('detallePaginator', { static: true }) paginator: MatPaginator;
  columns: string[];
  row: any;
  tipoConfiguracionEnum = TipoConfiguracionEnum;
  title: string;
  combobxArr: any[] = [];
  selected = -1;
  firstRow: any;
  jsonControls: any[] = [];

  constructor(
    private senderService: SenderService,
    private router: Router,
    private configuracionService: ConfiguracionService,
    private userValuesService: UserValuesService,
    private dialog: MatDialog,
    private _snackBar: SnackBarService
  ) {
    this.getConfiguracionFromService();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getTableValues();
    this.getABMGeneralControlesHTML();
    this.title = `Detalle configuración: ${this.configuracion.AliasTabla}`;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const keys = Object.keys(data);
      // ARRAY PARA EL INPUT DE FILTROS
      const columns = [
        'L_NOMBRE',
        'L_N_SECTOR',
        'N_AGRUPADOR',
        'N_INCIDENCIA',
        'L_AGRUPADOR',
        'L_PRODUCTIVIDAD',
        'APELLIDO',
        'NOMBRE',
        'ID_LEGAJO',
        'CUIL',
        'DNI',
        'N_FERIADO',
        'N_SECTOR',
        'N_EQUIPO',
        'TIPO',
        'N_USUARIO',
        'MAIL',
        'ROL',
        'ESTADO',
        'PERIODO'
      ].filter((x, index) => keys.some(a => a === x));
      if (columns.length > 0) {
        for (let index = 0; index < columns.length; index++) {
          if (data[columns[index]]) {
            let value = data[columns[index]].toString();
            if (!(/^\d+$/).test(value)) {
              value = value.toLowerCase();
            }
            if (value.includes(filter)) {
              return value.includes(filter);
            }
          }

        }
      }
      return;
    };
  }

  ngOnDestroy() {
    this.senderService.enviarObjetoConfiguracion(null);
  }

  private getABMGeneralControlesHTML() {

    this.paramEntity.Json = JSON.stringify({
      pIdEmpresa: 1
    });

    this.configuracionService.getABMGeneralControlesHTML(this.paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this.jsonControls = JSON.parse(result.Json);
      }
    });

  }

  private getConfiguracionFromService() {
    this.senderService.$objSourceConfiguracion.subscribe((config) => {
      if (config) {
        this.configuracion = config;
      } else {
        this.router.navigateByUrl('configuraciones');
      }
    });
  }

  private getTableValues() {
    const paramEntity = new ParamEntity();
    paramEntity.NombreTabla = this.configuracion.NombreTabla;
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;

    this.configuracionService.getTableValuesService(paramEntity).subscribe((result: any[]) => {
      if (result) {
        this.firstRow = result[0];
        const ignoredFields = [
          'BTN_MODIFICAR',
          'BTN_ANULAR',
          'BTN_NUEVO',
          'O_',
          'S_'
        ];
        this.columns = getKeyValue(result, ignoredFields)[0];
        this.columns.unshift('accion');
        this.dataSource.data = result;
        // OBTENGO LAS LISTAS PARA LOS COMBOBOX
        for (let key in this.dataSource.data[0]) {
          if (key.indexOf('S_') !== -1) {
            this.combobxArr.push({ key, value: JSON.parse(this.dataSource.data[0][key]) });
          }
        }
      }
    });
  }

  filtrarGrilla(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  getAbmDataElements(jsonControls: any, tipoAgrupador: string, tipoRequest: TipoConfiguracionEnum) {
    const arr: { tipoAgrupador, tipoRequest: TipoConfiguracionEnum, elements: HtmlComponent[] }[] = jsonControls;

    return arr.filter(x => x.tipoAgrupador === tipoAgrupador && x.tipoRequest === tipoRequest)[0];
  }


  openModal(tipo) {
    if(this.dataSource.data.length>0){
      this.configuracion.Tipo = tipo;
      this.configuracion.Elements = this.getAbmDataElements(this.jsonControls, this.configuracion.AliasTabla.toLowerCase(), tipo).elements || []; // ARMO LOS ELEMENTOS HTML PARA EL MODAL
      if (this.configuracion.Elements.findIndex(x => (x.Type === 'select' || x.Type === 'autocomplete') && (!x.Options || !x.Options.length)) !== -1) { // VALIDO SI LAS OPCIONES DEL COMBOBOX VIENEN ESTATICAS O SI NECESITO INSERTARLAS DEL SERVICIO
        this.convertArrayWithOptions();
      }
      let modalData: { typeModal, height } = this.getModalType(this.configuracion.AliasTabla.toLowerCase());

      this.configuracion.Row = this.row;
      this.configuracion.FilterList = this.combobxArr;
      const matConfig: MatDialogConfig = {
        width: '100%',
        maxWidth: this.configuracion.Elements.length > 1 ? '55vw' : '30vw',
        minHeight: modalData.height,
        height: 'auto',
        panelClass: ['modal-marcacion', 'modal-insert-config'],
        autoFocus: false,
        data: {
          configuracion: this.configuracion
        }
      };
      const dialogRef = this.dialog.open(modalData.typeModal, matConfig);
      dialogRef.beforeClosed().subscribe((result) => {
        if (result) {
          if (result.status) {
            this._snackBar.openSnackBar('snack-success', 'Se guardó correctamente', 3000);
            this.clearCheckbox();
            this.getTableValues();
          } else {
            const errMsj = 'Hubo un error. Intente nuevamente';
            this._snackBar.openSnackBar('snack-danger', result.mensaje ? result.mensaje : errMsj, 3000);
          }
        }
      });
    }else{
      this._snackBar.openSnackBar('snack-warning', 'Aguarde un momento por favor!', 3000);
    }
  }

  private convertArrayWithOptions() {
    this.configuracion.Elements.forEach(item => {
      if (item.Type === 'select' || item.Type === 'autocomplete') {
        item.Options = this.combobxArr.find(value => value.key === item.Referencia);
      }
    });
    this.configuracion.Elements.forEach(elementItem => { // CAMBIO LA ESTRUCTURA DE LAS OPCIONES
      if (elementItem.Options) {
        elementItem.Options = elementItem.Options.value;
      }
    })
  }

  private getModalType(agrupador) {
    let result: { typeModal, height } = { typeModal: null, height: null };
    switch (agrupador) {
      case 'incidencias agrupadores':
        result.typeModal = ModalIncidenciasAgrupadoresComponent;
        result.height = '200px';
        break;
      case 'incidencias':
        result.typeModal = ModalIncidenciasComponent;
        result.height = '200px';
        break;
      case 'nomina (sectores)':
        result.typeModal = ModalNominaSectoresComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '230px' : '300px';
        break;
      case 'nomina (relaciones laborales)':
        result.typeModal = ModalNominaRelacionesLabComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '230px' : '300px';
        break;
      case 'nomina':
        result.typeModal = ModalNominaAbmComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '230px' : '300px';
        break;
      case 'feriados':
        result.typeModal = ModalFeriadosComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '230px' : '220px';
        break;
      case 'usuarios (agrupadores)':
        result.typeModal = ModalUsuariosAgrupadoresComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '180px' : '180px';
        break;
      case 'patologias':
        result.typeModal = ModalPatologiasComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '180px' : '180px';
        break;
      case 'shooterbot (password de enrolamiento)':
        result.typeModal = ModalPasswordEnrolamientoComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '180px' : '180px';
        break;
      case 'secciones':
        result.typeModal = ModalSeccionesComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '180px' : '180px';
        break;
      case 'shooterbot (equipos)':
        result.typeModal = ModalEquiposComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '180px' : '180px';
        break;
      case 'periodos (cierres)':
        result.typeModal = ModalPeriodosCierresComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '180px' : '180px';
        break;
      case 'usuarios':
        result.typeModal = ModalUsuariosComponent;
        result.height = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? '180px' : '180px';
        break;
      default:
        break;
    }
    return result;
  }

  checkboxSelect(e: MatCheckbox, element) {
    this.row = (e.checked) ? element : null;
  }

  paintRow(row: any) {
    if (row.hasOwnProperty('O_ID_ESTADO') && row.O_ID_ESTADO.toString() === '0') {
      return true;
    }
    if (row.hasOwnProperty('ESTADO') && row.ESTADO.toString().toLowerCase() === 'anulado') {
      return true;
    }
    return false;
  }

  clearCheckbox() {
    this.selected = -1;
    this.row = null;
  }
}
