import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfiguracionJornada, Plantilla } from 'src/app/models/rrhh/configuracion-jornada';
import { ModalConfirmacionComponent } from '../../modals/modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-list-plantillas',
  templateUrl: './list-plantillas.component.html',
  styleUrls: ['./list-plantillas.component.scss']
})
export class ListPlantillasComponent implements OnInit {
  @Input() plantillasList: Plantilla[];
  @Output() plantillaSelectedEmit = new EventEmitter();
  plantillaSelected: Plantilla;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  getSelected(e) {
    if (e.option.selected) {
      this.plantillaSelected = e.option.value;
      const element = e.option.value;
      this.plantillaSelectedEmit.emit(element);
      return;
    }
    this.plantillaSelectedEmit.emit(null);
  }

  anular() {
    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '500px',
      height: '120px',
      autoFocus: false,
      data: {
        titulo: '¿Desea anular la plantilla?'
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        const index = this.plantillasList.findIndex(x => x === this.plantillaSelected);
        if (index !== -1) {
          this.plantillasList.splice(index, 1);
          this.plantillaSelectedEmit.emit(null);
        }
      }
    });
  }
}
