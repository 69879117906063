import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MatDatepicker, MatDialog, MatPaginator, MatTableDataSource, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { Nomina } from 'src/app/models/rrhh/nomina.model';
import { FileService } from 'src/app/services/file.service';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalNominaComponent } from '../modals/modal-nomina/modal-nomina.component';
import { ModalConfirmacionComponent } from '../modals/modal-confirmacion/modal-confirmacion.component';
import { getKeyValue } from 'src/app/functions/key-value';
import { ParamEntity } from 'src/app/models/general/param.model';
import { formatDate } from '@angular/common';
import moment, { Moment } from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-conceptos-especiales-pagados',
  templateUrl: './conceptos-especiales-pagados.component.html',
  styleUrls: ['./conceptos-especiales-pagados.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})
export class ConceptosEspecialesPagadosComponent implements OnInit {
  sectionObj = {} as Nomina;
  conceptoSelected: any;
  datePicker = new FormControl(moment());
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator, { static: true }) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  columns: string[];
  conceptosEspecialesCmb: CmbEntity;
  importe: number = 0;
  selected = -1;
  menuMarcacionOpened = false;
  loadingFile = false;
  idLegajo = -1;

  constructor(
    private userValuesService: UserValuesService,
    private novedadesService: NovedadesService,
    private fileService: FileService,
    private dialog: MatDialog,
    private _snackBar: SnackBarService
  ) { }

  ngOnInit() {
    this.getConceptosEspecialesCmb();
  }

  openModalNomina() {
    const dialogRef = this.dialog.open(ModalNominaComponent, {
      width: '1000px',
      height: '550px',
      panelClass: 'modal-marcacion',
      autoFocus: false
    });
    dialogRef.beforeClosed().subscribe((result: Nomina) => {
      if (result) {
        Object.assign(this.sectionObj, result);
        this.idLegajo=this.sectionObj.IdLegajo;
        this.limpiarCampos();
        this.consultarHistorial();
      }else{
        this.idLegajo=-1;
      }
    });
  }

  getSeleccionConcepto(e) {
    this.conceptoSelected = e.value;
    this.consultarHistorial();
  }

  consultarHistorial() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario,
      pIdLegajo: this.sectionObj.IdLegajo,
      pPeriodo: formatDate(this.datePicker.value, 'yyyyMM', 'en'),
      pIdConcepto: this.conceptoSelected
    });

    this.novedadesService.getConceptosEspecialesTable(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        if (!result.DataResponse.length) {
          this.dataSource.data = [];
          this.columns = [];
          return;
        }
        const ignoredFields = [
        ];
        this.columns = getKeyValue(result.DataResponse, ignoredFields)[0];
        this.columns.push('accion');
        this.dataSource.data = result.DataResponse;
      }
    });
  }

  private getConceptosEspecialesCmb() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdUsurio: this.userValuesService.getUsuarioValues.IdUsuario
    });

    this.novedadesService.getConceptosEspecialesCmb(paramEntity).subscribe((result: any) => {
      if (result) {
        this.conceptosEspecialesCmb = result;
      }
    });
  }

  soloNumeros(e, soloNumero: boolean) {
    if (soloNumero) {
      if (!(e.keyCode === 8
        || e.keyCode === 46
        || (e.keyCode >= 35 && e.keyCode <= 40)
        || (e.keyCode >= 48 && e.keyCode <= 57)
        || (e.keyCode >= 96 && e.keyCode <= 105)
        || (e.keyCode === 86 && e.ctrlKey === true)
        || e.keyCode === 188
        || e.keyCode === 190
        || e.keyCode === 110
      )) {
        e.preventDefault();
        return false
      }
    }
  }

  openModalConfirmacion() {
    if (this.validarCampos()) {
      const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
        width: '500px',
        height: '120px',
        autoFocus: false,
        data: {
          titulo: '¿Desea guardar?'
        }
      });
      dialogRef.beforeClosed().subscribe((result) => {
        if (result) {
          this.actualizarConcepto();
        }
      });
    }
  }

  private validarCampos() {
    return true;
  }

  actualizarConcepto(nuevoConcepto = true, row?: any) {
    const paramEntity = new ParamEntity();

    const json = {
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario,
      pIdRegistro: 0,
      pIdConcepto: this.conceptoSelected,
      pPeriodo: formatDate(this.datePicker.value, 'yyyyMM', 'en'),
      pImporte: this.importe,
      pIdLegajo: this.sectionObj.IdLegajo
    };

    if (!nuevoConcepto) {
      json.pIdRegistro = row.ID_REGISTRO;
      delete json.pIdConcepto;
      delete json.pPeriodo;
      delete json.pImporte;
    }
    paramEntity.Json = JSON.stringify(json);
    this.novedadesService.actualizarConcepto(paramEntity).subscribe((result: ResponseHelper) => {
      this.consultarHistorial();
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Se guardó correctamente', 3000);
      }
      this.importe = null;
    });
  }

  toggleMenuOpened() {
    this.menuMarcacionOpened = !this.menuMarcacionOpened;
  }

  private limpiarCampos() {
    this.conceptoSelected = null;
    this.datePicker = new FormControl(moment());
    this.importe = null;
    // this.sectionObj = {} as Nomina;
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.datePicker.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.datePicker.setValue(ctrlValue);
    this.consultarHistorial();
    datepicker.close();
  }

}
