import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment, { Moment } from 'moment';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { ConfiguracionJornada, DatosCalculadosTurno } from 'src/app/models/rrhh/configuracion-jornada';

@Component({
  selector: 'app-form-config-b',
  templateUrl: './form-config-b.component.html',
  styleUrls: ['./form-config-b.component.scss']
})
export class FormConfigBComponent implements OnInit, OnChanges {
  diasOptions: CmbEntity[] = [];
  form: FormGroup;
  HTeoricas: string;
  turnosSuperpuestos: boolean;
  controlMismosHorarios: boolean;
  datosCalculadosTurno: DatosCalculadosTurno = {} as DatosCalculadosTurno;

  @Input() configuracion: ConfiguracionJornada;
  @Input() set disableForm(value: boolean) {
    if (this.form) {
      if (value) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  @Input() set setClearForm(value) {
    if (value) {
      this.HTeoricas = '';
      this.turnosSuperpuestos = false;
      this.controlMismosHorarios = false;
      this.form.reset();
    }
  }

  @Output() formSubmitEmit = new EventEmitter();
  @Output() datosCalculadosTurnoEmit = new EventEmitter();


  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuracion) {
      this.configuracion = changes.configuracion.currentValue;
      if (this.configuracion.TurnoUno) {
        this.setFormValues();
        return;
      }
      if (this.form) {
        this.HTeoricas = '';
        this.turnosSuperpuestos = false;
        this.controlMismosHorarios = false;
        this.form.reset();
      }
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      diaIngreso: [],
      horaIngreso: [],
      margenIngreso: [],
      toleranciaIngreso: [],
      diaEgreso: [],
      horaEgreso: [],
      margenEgreso: [],
      toleranciaEgreso: []
    });

    this.setOptions();
    this.subscribeFormValueChange();
  }

  private setFormValues() {
    // INGRESO
    this.form.controls.horaIngreso.setValue(this.configuracion.TurnoUno.Hinicio);
    this.form.controls.margenIngreso.setValue(this.configuracion.TurnoUno.MargenInicio);
    this.form.controls.toleranciaIngreso.setValue(this.configuracion.TurnoUno.TolIngreso);


    // EGRESO
    this.form.controls.horaEgreso.setValue(this.configuracion.TurnoUno.Hegreso);
    this.form.controls.margenEgreso.setValue(this.configuracion.TurnoUno.MargenEgreso);
    this.form.controls.toleranciaEgreso.setValue(this.configuracion.TurnoUno.TolEgreso);


    this.HTeoricas = this.configuracion.TurnoUno.Hteoricas;

    enum dias {
      'MISMO_DIA' = 1,
      'ANTERIOR' = 2,
      'POSTERIOR' = 3
    }

    let diaIngreso = this.configuracion.TurnoUno.SignoIngreso == '-' ? dias.ANTERIOR :
      this.configuracion.TurnoUno.SignoIngreso == '+' ? dias.POSTERIOR : dias.MISMO_DIA;
    let diaEgreso = this.configuracion.TurnoUno.SignoEgreso == '-' ? dias.ANTERIOR :
      this.configuracion.TurnoUno.SignoEgreso == '+' ? dias.POSTERIOR : dias.MISMO_DIA;

    this.form.controls.diaIngreso.setValue(diaIngreso);
    this.form.controls.diaEgreso.setValue(diaEgreso);


    this.ValidarHoras()
  }

  private setOptions() {
    // SELECT OPTIONS
    const dias = [
      'Mismo día',
      'Anterior',
      'Posterior'
    ];

    dias.forEach((element, index) => {
      const estado = new CmbEntity();
      estado.Id = index + 1;
      estado.Detalle = element;
      this.diasOptions.push(estado);
    });
  }

  convert(e) {
    if (e.inputType !== "deleteContentBackward") {
      if (e.target.value.length === 2) {
        e.target.value = e.target.value + ':';
      }
    }

    this.ValidarHoras();

  }

  soloNumeros(e, soloNumero: boolean) {
    if (soloNumero) {
      if (!(e.keyCode === 8
        || e.keyCode === 46
        || (e.keyCode >= 35 && e.keyCode <= 40)
        || (e.keyCode >= 48 && e.keyCode <= 57)
        || (e.keyCode >= 96 && e.keyCode <= 105)
        || (e.keyCode === 86 && e.ctrlKey === true)
      )) {
        e.preventDefault();
        return false
      }
    }
  }

  private subscribeFormValueChange() {
    this.form.valueChanges.subscribe((value) => {
      this.formSubmitEmit.emit(value);
    });
  }


  getSeleccionDiaIngreso(e) {
    this.ValidarHoras()
  }

  getSeleccionDiaEgreso(e) {
    this.ValidarHoras()
  }

  ValidarHoras() {
    this.turnosSuperpuestos = false;
    this.controlMismosHorarios = false;

    //************************ VARIABLES ************************
    enum dias {
      'MISMO_DIA' = 1,
      'ANTERIOR' = 2,
      'POSTERIOR' = 3
    }


    //************************ Validación de horario completo ************************
    let vHoraIngreso: string = this.form.controls.horaIngreso.value;
    let vHoraEgreso: string = this.form.controls.horaEgreso.value;

    if (vHoraIngreso == null || vHoraEgreso == null) {
      return;
    }
    if (vHoraIngreso.length != 5 || vHoraEgreso.length != 5) {
      return;
    }
   




    //************************ INGRESO ************************

    var dayIngreso: Date = new Date();

    if (this.form.controls.diaIngreso.value == dias.ANTERIOR) {
      dayIngreso.setDate(dayIngreso.getDate() - 1);
    }

    if (this.form.controls.diaIngreso.value == dias.POSTERIOR) {
      dayIngreso.setDate(dayIngreso.getDate() + 1);
    }
      

    var diaIngreso = dayIngreso.getDate();
    var mesIngreso = dayIngreso.getMonth() + 1;
    var añoIngreso = dayIngreso.getFullYear()
    var FechaIngreso = String(diaIngreso + "/" + mesIngreso + "/" + añoIngreso);


    var vHIngreso = moment(FechaIngreso, 'DD/MM/YYYY').add(moment.duration(this.form.controls.horaIngreso.value));
    var vHIngresoConMargen = moment(FechaIngreso, 'DD/MM/YYYY').add(moment.duration(this.form.controls.horaIngreso.value)).subtract(moment.duration(this.form.controls.margenIngreso.value));


       //************************ EGRESO ************************

    var dayEgreso: Date = new Date();


    if (this.form.controls.diaEgreso.value == dias.ANTERIOR) {
      dayEgreso.setDate(dayEgreso.getDate() - 1);
    }

    if (this.form.controls.diaEgreso.value == dias.POSTERIOR) {
      dayEgreso.setDate(dayEgreso.getDate() + 1);
    }
 
    var diaEgreso = dayEgreso.getDate();
    var mesEgreso = dayEgreso.getMonth() + 1;
    var añoEgreso = dayEgreso.getFullYear()
    var FechaEgreso = String(diaEgreso + "/" + mesEgreso + "/" + añoEgreso);


    var vHEgreso = moment(FechaEgreso, 'DD/MM/YYYY').add(moment.duration(this.form.controls.horaEgreso.value));
    var vHEgresoConMargen = moment(FechaEgreso, 'DD/MM/YYYY').add(moment.duration(this.form.controls.horaEgreso.value)).add(moment.duration(this.form.controls.margenEgreso.value));


       //************************ CALCULOS ************************

      var durationEntradaSalida = moment.duration(vHEgreso.diff(vHIngreso));
      var MMTeoricas: number;
      var HHTeoricas: number;


      if (vHEgreso > vHIngreso) {
          MMTeoricas = durationEntradaSalida.asMinutes() * 1;
        HHTeoricas = Number((MMTeoricas / 60).toFixed(2)) * 1;
        this.controlMismosHorarios = false;
      } else {
        HHTeoricas = 0.00;
        this.controlMismosHorarios = true;
      }

      this.HTeoricas = HHTeoricas.toString();

    



    var durationMargenesEntradaSalida = moment.duration(vHEgresoConMargen.diff(vHIngresoConMargen));
    var MMTeoricasMargenes = durationMargenesEntradaSalida.asMinutes();
    var HHTeoricasMargenes = Number((MMTeoricasMargenes / 60).toFixed(2));


    //************************ ENVIAR DATA ************************

    this.datosCalculadosTurno.horaIngreso = vHIngreso;
    this.datosCalculadosTurno.horaEgreso = vHEgreso;
    this.datosCalculadosTurno.horaIngresoConMargen = vHIngresoConMargen;
    this.datosCalculadosTurno.horaEgresoConMargen = vHEgresoConMargen;
    this.datosCalculadosTurno.hTeoricas = HHTeoricas;
    this.datosCalculadosTurno.hTeoricasMargen = HHTeoricasMargenes;
    this.datosCalculadosTurnoEmit.emit(this.datosCalculadosTurno);


     



  }



}
