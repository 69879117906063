import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectionList } from '@angular/material';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-list-b',
  templateUrl: './list-b.component.html',
  styleUrls: ['./list-b.component.scss']
})
export class ListBComponent implements OnInit, AfterViewInit {
  control = new FormControl();
  result: any[] = [
    { id: 1, tipo: 'Cronos' },
    { id: 1, tipo: 'Punto' },
    { id: 2, tipo: '206' },
    { id: 3, tipo: 'Clio' },
    { id: 3, tipo: 'Sandero' }
  ];
  @ViewChild('matSelection', { static: false }) matSelection: MatSelectionList;

  constructor(
    private dataShareService: DataShareService
  ) {

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.dataShareService.selectedElement$.subscribe((selected: any) => {
      if (selected) {
        this.matSelection.options.forEach(x => {
          if (selected.value.id === x.value.id) {
            x._setSelected(selected.selected);
          }
        })
      }
    });
  }

  getSelected(e) {
    if (e.option.selected) {
      const element = e.option.value;
      // this.elementSelectedEmit.emit(element);
      return;
    }
  }

  compareObjects(optionA, optionB) {
    if (optionA.IdJornada === optionB.IdJornada) {
      return true;
    }
    return false;
  }

  isSelected(item) {
    // return this.result.some(a => this.elementsSelected.findIndex(b => b.id === a.id) !== -1);
  }

}
