import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Usuario } from 'src/app/models/general/usuario.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { TableroService } from 'src/app/services/tablero.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalConfirmacionComponent } from '../modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-modal-actualizacion-jornada',
  templateUrl: './modal-actualizacion-jornada.component.html',
  styleUrls: ['./modal-actualizacion-jornada.component.scss']
})
export class ModalActualizacionJornadaComponent implements OnInit {
  objeto: { titulo, obj, rangeDates };
  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  usuario: Usuario;
  lstJornadas: CmbEntity[] = [];

  // DTP Range
  start = new FormControl({ value: null, disabled: true })
  end = new FormControl({ value: '', disabled: true });

  minDate: Date;

  dateRows: Array<{ fecha_desde, fecha_hasta, idJornada }> = [];

  constructor(
    public dialogRef: MatDialogRef<ModalActualizacionJornadaComponent>,
    private userValuesService: UserValuesService,
    private novedadesService: NovedadesService,
    public dialog: MatDialog,
    private _snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data,
    private tableroService: TableroService
  ) {
    this.usuario = this.userValuesService.getUsuarioValues;
    this.objeto = data;
  }

  ngOnInit() {
    this.loadJornadasCmb();
    this.getMindate();
  }

  cerrarModal(result) {
    this.objeto = { obj: [], rangeDates: {}, titulo: '' };
    this.dialogRef.close(result);
  }

  private getMindate() {
    let paramEntity = new ParamEntity<any>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    this.tableroService.getMindateService(paramEntity).subscribe((result: Date) => {
      if (result) {
        this.minDate = new Date(result);
      }
    });
  }

  getSeleccionJornada(e) {
    // this.objeto.IdJornada = e.option.value.Id;
  }

  displayFn(item: CmbEntity): string {
    return item && item.Detalle ? item.Detalle : '';
  }

  loadJornadasCmb() {
    let params = [];
    params.push(null);
    params.push(this.userValuesService.getUsuarioValues.IdEmpresa);
    params.push(this.usuario.IdUsuario);
    this.novedadesService.getListJornadasUser(params).subscribe((result: CmbEntity[]) => {
      this.lstJornadas = result;
      this.subscribeEventChange();
    });

    //Fer 24-07-2021: set default value
    // let jorn = new CmbEntity;
    // jorn.Id = this.objeto.IdJornada;
    // jorn.Detalle = this.objeto.Njornada;
    // this.myControl.setValue(jorn);
  }

  private subscribeEventChange() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Detalle),
        map(value => this._filter(value))
      );
  }

  private _filter(value): any[] {
    const filterValue = value.toLowerCase();
    return this.lstJornadas.filter(option => option.Detalle.toLowerCase().includes(filterValue));
  }

  private guardar() {
    const arr = [];
    this.objeto.obj.forEach(element => {
      const id_legajo = element.ID_LEGAJO;
      const rows = this.dateRows;

      const obj = { id_legajo, rows };
      arr.push(obj);
    });

    const param = new ParamEntity();
    param.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    param.IdUsuario = this.usuario.IdUsuario;
    param.Json = JSON.stringify(arr);

    this.novedadesService.postJornadasProgramadasUpdate(param).subscribe((result: ResponseHelper) => {
      this._snackBar.openSnackBar('snack-success', result.Mensaje, 3000);
      if (result.Ok) {
        this.cerrarModal(arr);
      }
    }, (error) => { this._snackBar.openSnackBar('snack-error', 'Hubo un error. Intente nuevamente', 3000); });
  }

  openModalData() {
    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '400px',
      height: '120px',
      autoFocus: false,
      data: {
        titulo: '¿Desea guardar los cambios?'
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        this.guardar();
      }
    });
  }

  addDate() {
    let exists = false;
    if (!this.dateRows.length) {
      exists = this.dateRows.some(x =>
        x.fecha_desde === formatDate(this.start.value.toDate(), 'dd/MM/yyyy', 'es-AR') ||
        x.fecha_hasta === formatDate(this.end.value.toDate(), 'dd/MM/yyyy', 'es-AR')
      );
    }

    if (!exists) {
      this.dateRows.push({
        fecha_desde: formatDate(this.start.value.toDate(), 'dd/MM/yyyy', 'es-AR'),
        fecha_hasta: formatDate(this.end.value.toDate(), 'dd/MM/yyyy', 'es-AR'),
        idJornada: this.myControl.value.Id
      });

      this.start = new FormControl({ value: null, disabled: true })
      this.end = new FormControl({ value: '', disabled: true });
      this.myControl.setValue('');
      this.subscribeEventChange();
    }
  }

  getJornada(id) {
    return this.lstJornadas.find(x => x.Id === id).Detalle;
  }

}
