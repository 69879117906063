
import { Marcacion } from './../rrhh/marcacion.model';
import { DateTimeEntity } from './../sistema/dateTimeEntity';
export class ParamEntity<T> {
  IdUsuario: number;
  Nusuario: string;
  FechaStr: string;
  FechaDate: Date;
  Fecha: DateTimeEntity;
  IdEmpresa: number;
  IdDocumento: number;
  IdLegajo: number;
  IdJornada: number;
  IdIncidencia: number;
  GenericEntity: T;
  Tipo: any;
  IdPatologia: number;
  FechaDateArray: Date[] = [];
  CurrentDateFront: Date;
  Periodo: any;
  IdSeccion: number;
  IdTcontrol: number;
  SpName: string;
  NombreTabla: string;
  Json: string;
  Accion: string;
  PeriodoDesde: any;
  PeriodoHasta: any;
  ClearTable: boolean;
  GenericData: any;
  Inactividad: boolean;
  TiempoInactividad?: number;
  Mail: string;
  Password: string;
  IdEstado: number;
  IdRol: number;
  IdPlantillasNomina: any;
  IdPlantillasJornada: any;
}
