import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-abm',
  templateUrl: './modal-abm.component.html',
  styleUrls: ['./modal-abm.component.scss']
})
export class ModalAbmComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalAbmComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder
  ) {
    //console.log(data)
  }

  ngOnInit() {
    this.createForm();
  }

  cerrarModal(result: { status, mensaje }) {
    this.dialogRef.close(result);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      descripcion: []
    })
  }

}
