import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSidenavContainer, MatSidenavContent } from '@angular/material';

@Component({
  selector: 'app-home-autogestion',
  templateUrl: './home-autogestion.component.html',
  styleUrls: ['./home-autogestion.component.scss']
})
export class HomeAutogestionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
